import style from "./GoBack.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text: string;
  onClick: () => void;
}

export default function GoBack(props: Props) {
  return (
    <div {...props} className={classNames(style.goBack, props.className)} onClick={props.onClick}>
      <FontAwesomeIcon icon={faArrowLeft} /> <span>{props.text}</span>
    </div>
  );
}
