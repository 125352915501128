import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import CatalogsList from "./pages/CatalogsList/CatalogsList";
import Login from "./pages/Auth/Login";
import ReturnLogin from "./pages/Auth/ReturnLogin";
import { UserContextType, useUser } from "./context/UserContext";
import { HasCheckLoginContextType, useHasCheckLogin } from "./context/HasCheckLoginContext";
import api, { url_core } from "./api/api";
import { JWT_PERIOD } from "./utils/constantes";
import { ReactQueryDevtools } from "react-query/devtools";
import NeedLogin from "./utils/NeedLogin";
import { formatUserRoleToString, MY_DOMAIN } from "./utils/utils";
import Catalog from "./pages/Catalog/Catalog";
import ProductList from "./pages/ProductList/ProductList";
import Winylo from "./winylo";
import CreateCategory from "./pages/CreateCategory/CreateCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faHome, faThLarge } from "@fortawesome/free-solid-svg-icons";
import CategoryList from "./pages/CategoryList/CategoryList";
import CatalogPublic from "./pages/CatalogPublic/CatalogPublic";
import Error from "./pages/Error/Error";
import "toastify-js/src/toastify.css";
import { ShepherdTour } from "react-shepherd";
import Home from "./pages/Home/Home";
import CreateProduct from "./pages/CreateProduct/CreateProduct";
import SubCategoriesList from "./pages/SubCategoriesList/SubCategoriesList";
import RedirectToStore from "./pages/RedirectToStore/RedirectToStore";

function GetSidePannel({ children }: any) {
  return (
    <Winylo.SidePannel
      actions={[
        {
          text: "",
          actions: [
            {
              text: "Accueil",
              path: "/",
              icon: <FontAwesomeIcon icon={faHome} />,
            },
            {
              className: "onboarding-createcatalog-0th onboarding-detailledcatalog-1st onboarding-sharecatalog-1st",
              text: "Catalogues",
              path: "/catalogs",
              icon: <FontAwesomeIcon icon={faClipboardList} />,
            },
            {
              className: "onboarding-createcategory-1st onboarding-detailledcategory-0th",
              text: "Catégories",
              path: "/categories",
              icon: <FontAwesomeIcon icon={faThLarge} />,
            },
            {
              className: "onboarding-createproduct-1st onboarding-detailledproduct-1st",
              text: "Produits",
              path: "/products",
              icon: <FontAwesomeIcon icon={faClipboardList} />,
            },
          ],
        },
      ]}
    >
      {children}
    </Winylo.SidePannel>
  );
}

function App() {
  const [user, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
        label: "fg",
      },
    },
    useModalOverlay: true,
    exitOnEsc: true,
    keyboardNavigation: false,
  };

  useEffect(() => {
    api.loginApi
      .getMe()
      .then((data) => {
        console.log("%cReconexion avec le JWT", "color: blue");

        setUser(data.user);
        setHasCheckLogin(true);
        refetchMe();
      })
      .catch((err) => {
        console.log(err);
        setHasCheckLogin(true);
        setUser(undefined);
      });

    function refetchMe() {
      api.loginApi
        .getMe()
        .then((data) => {
          console.log("%cAcualisation du JWT", "color: blue");

          localStorage.setItem("jwt", data.jwt);

          setUser(data.user);

          setTimeout(refetchMe, JWT_PERIOD);
        })
        .catch((err) => {
          console.log(err);
          setUser(undefined);
        });
    }
  }, [setUser, setHasCheckLogin]);

  const navigate = useNavigate();
  const location = useLocation();

  const freeUrl = ["/login"];

  function logout() {
    localStorage.removeItem("jwt");
    setUser(undefined);

    if (!freeUrl.includes(location.pathname) && !location.pathname.startsWith("/c/")) {
      navigate("/login#redirect=" + location.pathname);
    }
  }

  return (
    <>
      {user && !location.pathname.startsWith("/c/") && !location.pathname.startsWith("/error") && (
        <Winylo.TopBar
          brandLogoSrc={`${MY_DOMAIN}/catalogueLogo.png`}
          brandLogoClick={() => navigate("/")}
          brandLogoText="Catalogue Digital"
          logout={logout}
          username={user.firstname + " " + user.lastname.substring(0, 1) + "."}
          userRole={formatUserRoleToString(user.roles)}
          actions={[
            {
              text: "Retours aux apps",
              onClick: () => {
                window.location.href = url_core;
              },
            },
          ]}
        />
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/return-login" element={<ReturnLogin />} />
        <Route path="/redirect-to-store" element={<RedirectToStore />} />
        {/* <Route
            path="/"
            element={getSidePanel(
              <NeedLogin>
                <Home />
              </NeedLogin>
            )}
          /> */}
        <Route
          path="/"
          element={
            <GetSidePannel>
              <NeedLogin>
                <Home />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route
          path="/catalogs"
          element={
            <GetSidePannel>
              <NeedLogin>
                <CatalogsList />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route
          path="/catalog/:id/edit"
          element={
            <NeedLogin>
              <Catalog />
            </NeedLogin>
          }
        />
        <Route
          path="/products"
          element={
            <GetSidePannel>
              <NeedLogin>
                <ProductList />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route
          path="/products/create"
          element={
            <GetSidePannel>
              <NeedLogin>
                <CreateProduct />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        {/* <Route
            path="/products/create"
            element={getSidePanel(
              <NeedLogin>
                <CreateProduct />
              </NeedLogin>
            )}
          /> */}
        <Route
          path="/products/:id/edit"
          element={
            <GetSidePannel>
              <NeedLogin>
                <CreateProduct />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route
          path="/categories"
          element={
            <GetSidePannel>
              <NeedLogin>
                <CategoryList />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route
          path="/categories/create"
          element={
            <GetSidePannel>
              <NeedLogin>
                <CreateCategory />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route
          path="/categories/:id/edit"
          element={
            <GetSidePannel>
              <NeedLogin>
                <CreateCategory />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route
          path="/categories/:parent/subcategories/:id/edit"
          element={
            <GetSidePannel>
              <NeedLogin>
                <CreateCategory />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route
          path="/categories/:id/subcategories"
          element={
            <GetSidePannel>
              <NeedLogin>
                <SubCategoriesList />
              </NeedLogin>
            </GetSidePannel>
          }
        />
        <Route path="/c/:token" element={<CatalogPublic />} />
        <Route path="/error" element={<Error />} />
      </Routes>
      <ReactQueryDevtools />
    </>
  );
}

export default App;
