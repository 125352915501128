import React, { useEffect, useState, useRef } from "react";

import { ReactComponent as Dot } from "./circle.svg";

import defaultStyle from "./Carousel.module.css";
import classNames from "classnames";
import { Media } from "../../api/_type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCaretLeft, faCaretRight, faVideo } from "@fortawesome/free-solid-svg-icons";
import { useIsMobile } from "../../utils/utils";
import SwipeItem from "./SwipeItem";

interface Props {
  medias: Media[];
  style?: any;
  unselectedColor?: string;
  selectedColor?: string;
  isMini?: boolean;
  canZoom?: boolean;
  thumbnails?: boolean;
}

export default function Carousel({ medias, style, unselectedColor, selectedColor, isMini, canZoom, thumbnails }: Props) {
  const [currentPicture, setCurrentPicture] = useState(0);
  const [isAnimated, setisAnimated] = useState<number>(-1);

  const isMobile = useIsMobile();
  const ref = useRef<HTMLVideoElement | null>(null);

  if (style === undefined) {
    style = defaultStyle;
  }
  if (selectedColor === undefined) {
    selectedColor = "#F4D03F";
  }
  if (unselectedColor === undefined) {
    unselectedColor = "white";
  }

  function isVisible(index: number, currentPicture: number, maxIndex: number) {
    const offset = currentPicture === 0 || currentPicture === maxIndex ? 2 : 1;

    if (currentPicture + offset < index) {
      return false;
    } else if (index < currentPicture - offset) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (ref) {
      ref.current?.addEventListener(
        "loadedmetadata",
        function () {
          this.currentTime = 1;
        },
        false
      );

      return ref.current?.removeEventListener(
        "loadedmetadata",
        function () {
          this.currentTime = 1;
        },
        false
      );
    }
  }, [ref]);

  function toggleSwipe(direction: number) {
    if (direction == -1) {
      if (currentPicture >= 1) {
        setCurrentPicture(currentPicture + direction);
      }
    }
  }

  return (
    <div>
      {medias.length === 0 ? (
        <div
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/no_media.jpg)`,
            backgroundSize: "cover",
          }}
          className={classNames(style.productCover)}
        ></div>
      ) : (
        <div style={{ position: "relative" }}>
          <SwipeItem
            canSwipeRight={currentPicture - 1 >= 0}
            canSwipeLeft={currentPicture + 1 < medias.length}
            onSwipe={(direction) => setCurrentPicture(currentPicture - direction)}
          >
            <div
              onClick={() => canZoom && window.open(medias[currentPicture].uri, "_blank")}
              style={{
                backgroundImage: `url(${thumbnails ? medias[currentPicture].uri_thumbnail : medias[currentPicture].uri})`,
                cursor: canZoom ? "zoom-in" : "unset",
              }}
              className={style.productCoverMultiple}
            >
              {medias[currentPicture].mimeType?.startsWith("video") ? (
                isMobile ? (
                  <div style={{ position: "relative" }}>
                    <video ref={ref} controls={false} muted src={medias[currentPicture].uri} preload="metadata" className={style.productCover} />
                    <FontAwesomeIcon className={style.videoIcon} icon={faVideo} />
                  </div>
                ) : (
                  <video
                    controls={isMini || thumbnails ? false : true}
                    src={medias[currentPicture].uri}
                    className={style.productCover}
                    disablePictureInPicture
                    disableRemotePlayback
                    muted
                    onPlay={(e) => e.preventDefault()}
                    onVolumeChange={(e) => e.preventDefault()}
                  />
                )
              ) : null}
            </div>
          </SwipeItem>
          {medias.length > 1 ? (
            <div className={classNames(style.dotContainer, style.left)} onClick={(e) => e.stopPropagation()}>
              {medias.map((m, i) => (
                <React.Fragment key={i}>
                  {isVisible(i, currentPicture, medias.length - 1) && (
                    <Dot
                      className={classNames(
                        style.dot,
                        isAnimated !== -1 &&
                          currentPicture === i &&
                          isAnimated !== currentPicture &&
                          (isAnimated < currentPicture ? style.animationLeft : style.animationRight)
                      )}
                      style={{
                        color: currentPicture === i ? selectedColor : unselectedColor,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setisAnimated(i);
                      }}
                      onAnimationEnd={() => {
                        setCurrentPicture(isAnimated);
                        setisAnimated(-1);
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
