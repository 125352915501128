import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faBug, faEnvelope, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, ReactNode } from "react";
import icons from "../utils/icons";

import { ReactComponent as WebsiteIcon } from "../svg/website.svg";

interface PatchNote {
  title: string;
  previews: ReactNode[];
  content: ReactNode;
}

interface Tag {
  label: string;
  color: string;
}

class TagType {
  static readonly NEW: Tag = { label: "Nouveauté", color: "var(--green)" };
  static readonly UPDATE: Tag = { label: "Amélioration", color: "var(--primary-color)" };
  static readonly CORRECTED: Tag = { label: "Correction", color: "var(--red-lighter)" };
}

const newsStyle: CSSProperties = {
  padding: "0.2em 0.6em 0.3em",
  borderRadius: "12px",
  fontWeight: "500",
  color: "var(--background)",
};

const newsLightStyle: CSSProperties = {
  height: "25px",
  width: "25px",
  borderRadius: "50%",
  display: "inline-block",
};

const ulStyle: CSSProperties = {
  listStyle: "none",
  padding: 0,
  margin: "1rem",
};

const liStyle: CSSProperties = {
  marginBottom: "1rem",
};

const titleStyle: CSSProperties = {
  fontWeight: 600,
};

const Divider = () => {
  return <div style={{ marginBottom: "0.5rem" }} />;
};

const Nametag = ({ title, type, lightFormat }: { title: string; type: Tag; lightFormat?: boolean }) => {
  const tagStyle: CSSProperties = lightFormat ? newsLightStyle : newsStyle;
  return (
    <span style={lightFormat ? { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } : {}}>
      <span style={{ backgroundColor: type.color, ...tagStyle }}>{lightFormat ? " " : type.label}</span>{" "}
      <span style={lightFormat ? {} : titleStyle}>{title}</span>
    </span>
  );
};

export const patchNotes: PatchNote[] = [
  {
    title: "Mise à jour du 21/10/2024",
    previews: [<Nametag title="Affichage des vidéos" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Affichage des vidéos" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Plusieurs modifications concernant l'affichage des vidéos dans un catalogue on été efféctuées. Les vidéos boutons de contrôles sont
            maintenant affichés sur la page produit et les vidéos s'arrêtent automatiquement lorsqu'on change de produit. De plus, seule la première
            image des vidéos sera chargée sur la page globale du catalogue.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 16/08/2023",
    previews: [
      <Nametag title="Compteur de vues" type={TagType.NEW} lightFormat />,
      <Nametag title="Optimisation du catalogue" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Compteur de vues" type={TagType.NEW} />
          <Divider />
          <span>Vous pouvez maintenant voir le nombre de vues de vos catalogues.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Optimisation du catalogue" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Les modifications dans le catalogue ont été optimisées de manière à être moins gourmandes en ressource et éviter des rechargements
            intempestif. Cela évite aussi de devoir sélectionner de nouveau une catégorie après chaque modification.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 27/02/2023",
    previews: [<Nametag title="Scroll amélioré" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Scroll amélioré" type={TagType.UPDATE} />
          <Divider />
          <span>
            La position du scroll est maintenant sauvegardée lorsque l'on va dans les détails d'un produit et que l'on retourne au catalogue.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 25/01/2023",
    previews: [<Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Les tutoriels intéractifs sont masqués pour les utilisateurs n'étant pas <span style={{ fontWeight: 600 }}>Administrateur</span>.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 14/11/2022",
    previews: [
      <Nametag title="Informations de l'entreprise" type={TagType.NEW} lightFormat />,
      <Nametag title="Contacts" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Ordre des listes" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Informations de l'entreprise" type={TagType.NEW} />
          <Divider />
          <span>Vous avez maintenant la possibilité d'ajouter des informations sur votre entreprise pour chaque catalogue.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Contacts" type={TagType.UPDATE} />
          <Divider />
          <span>
            Le bouton <span style={titleStyle}>Prendre contact</span> <FontAwesomeIcon icon={faEnvelope} /> a été ajouté à la page de détails d'un
            produit.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Ordre des listes" type={TagType.CORRECTED} />
          <Divider />
          <span>L'ordre des listes de séléction de catégories et de produits a été modifié de façon à mettre en avant les lignes cochées.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 09/11/2022",
    previews: [<Nametag title="Améliorations visuelles" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Améliorations visuelles" type={TagType.UPDATE} />
          <Divider />
          <span>L'apparence du catalogue sur téléphone a été améliorée.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 19/10/2022",
    previews: [
      <Nametag title="Demandes de contact" type={TagType.NEW} lightFormat />,
      <Nametag title="Swipez !" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Affichage des vidéos" type={TagType.UPDATE} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Demandes de contact" type={TagType.NEW} />
          <Divider />
          <span>
            Vous pouvez dorénavant activer le paramètre <span style={{ fontWeight: "600" }}>Demande de contact</span> sur vos catalogues.
          </span>
          <Divider />
          <span>
            Cela affichera une icone <FontAwesomeIcon icon={faEnvelope} /> pour chaque produit. En cliquant sur cette icône un formulaire de contact
            s'ouvre et permet à votre clientère de vous contacter.
          </span>
          <Divider />
          <span>
            Le mail s'envoie à toutes les personnes avec le rôle <span style={{ fontWeight: "600" }}>Administrateur</span> sur l'application.
          </span>
          <Divider />
          <span style={{ fontStyle: "italic", color: "var(--input-text-disabled)" }}>
            Cette fonctionnalité est en phase d'éssais, n'hésitez pas à nous faire des retours !
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Swipez !" type={TagType.UPDATE} />
          <Divider />
          <span>
            Sur mobile, il vous est maintenant possible de swiper vers la droite ou vers la gauche afin de changer d'image depuis un catalogue.
          </span>
          <Divider />
          <span>Cela rend l'action plus ergonomique qu'utiliser les ronds sous l'image.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Affichage des vidéos" type={TagType.UPDATE} />
          <Divider />
          <span>
            L'affichage des vidéos sur mobile a été modifié de manière à afficher le début de la vidéo, et non un écran noir comme c'était le cas
            auparavant.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 12/10/2022",
    previews: [
      <Nametag title="Importations de médias" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Descriptions des produits" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Désélection des produits" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Importations de médias" type={TagType.UPDATE} />
          <Divider />
          <span>La fenêtre d'importations des médias de Photos & Vidéos a été refaite.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Descriptions des produits" type={TagType.UPDATE} />
          <Divider />
          <span>Le style des descriptions des produits depuis un catalogue est maintenant affiché.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Désélection des produits" type={TagType.UPDATE} />
          <Divider />
          <span>
            Un produit peut-être désélectionner directement depuis un catalogue avec l'icône <FontAwesomeIcon icon={faTrash} />.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>Les zones de texte des descriptions ne gardaientt pas une largeur fixe.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 04/10/2022",
    previews: [
      <Nametag title="Ordre des listes" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Ordre des listes" type={TagType.UPDATE} />
          <Divider />
          <span>L'ordre des listes a été changé pour afficher les éléments du plus récent au plus ancien.</span>
        </li>
        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Le bouton <span style={{ fontWeight: 600 }}>Site Web</span> <WebsiteIcon width={"1rem"} /> ne renvoyait pas vers le lien défini.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 27/09/2022",
    previews: [<Nametag title="Taille des fichiers" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Taille des fichiers" type={TagType.UPDATE} />
          <Divider />
          <span>La taille maximale des fichiers est passée de 6 Mo à 10 Mo.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 21/09/2022",
    previews: [
      <Nametag title="Gestion des sous-catégories" type={TagType.NEW} lightFormat />,
      <Nametag title="Zoom d'image" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Carousel d'images" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Gestion des sous-catégories" type={TagType.NEW} />
          <Divider />
          <span>
            Les sous-catégories ont désormais un espace des gestion depuis lequel il est possible de les créer, de les modifier et d'y assigner des
            produits.
          </span>
          <Divider />
          <span>
            Cet espace est accessible depuis la liste des catégories en cliquant sur le bouton{" "}
            <span style={{ fontWeight: 600, color: "var(--primary-color)", whiteSpace: "nowrap" }}>Sous-Catégories</span>
            <FontAwesomeIcon style={{ fontSize: "1.25rem", marginLeft: "0.5rem", marginRight: "0.5rem" }} icon={icons.v6_faArrowRightToBracket} />
            d'une de vos catégories.
          </span>
          <Divider />
          <span>De plus, vous avez maintenant la possibilité de définir une image et une description à vos sous-catégories.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Zoom d'image" type={TagType.UPDATE} />

          <Divider />
          <span>Le système de zoom sur une image a été amélioré. Au lieu de vous ouvrir un nouvel onglet, l'image sera plus grande au click.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Carousel d'images" type={TagType.UPDATE} />

          <Divider />
          <span>
            Le carousel a été amélioré pour supporter l'affichage d'un grand nombre d'images. Une animation a été ajoutée afin de rendre le changement
            d'image plus perceptible.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />,
          <Divider />
          <span>Les icônes de la barre du haut n'étaient pas toujours de la bonne taille.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 07/09/2022",
    previews: [<Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>Correction d'un bug qui causait une impossibilité de cliquer sur les boutons "Sites et réseaux" depuis un catalogue publique.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 05/09/2022",
    previews: [<Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>Correction d'un bug avec les médias de Photos & Vidéos qui ne s'affichaient pas et qui pouvait causer une page blanche.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 31/08/2022",
    previews: [
      <Nametag title="Consommation du stockage" type={TagType.NEW} lightFormat />,
      <Nametag title="Meilleures des performances" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Correction de bugs" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Consommation du stockage" type={TagType.NEW} />
          <Divider />
          <span>La consommation du stockage de vos médias dans Catalogue est maintenant visible depuis l'application centrale.</span>
          <Divider />
          <span>
            Des messages d'erreurs personnalisés apparaîtront lorsque vous n'avez plus d'espace disponible et que vous essayez d'ajouter un média.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Meilleures des performances" type={TagType.UPDATE} />
          <Divider />
          <span>
            Dorénavant, lorsque vous avez beaucoup de catégories, celles-ci sont chargées par paquets afin d'améliorer les performances et la
            fluidité.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bugs" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Lorsque l'on avait beaucoup de produit, la liste permettant de les ajouter à une catégorie ne pouvait en afficher qu'un certains nombre.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 04/07/2022",
    previews: [
      <Nametag title="Modification d'un produit" type={TagType.NEW} lightFormat />,
      <Nametag title="Ajout des sous catégories" type={TagType.NEW} lightFormat />,
      <Nametag title="Ordre des catégories" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Ordre des médias" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Corrections de bugs" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Modification d'un produit" type={TagType.NEW} />
          <Divider />
          <span>Vous pouvez désormais modifier les produits directement depuis la page du catalogue.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Ajout des sous catégories" type={TagType.NEW} />
          <Divider />
          <span>
            Vous avez maintenant la possibilité de créer des sous-catégorie au sein d'une catégorie afin d'améliorer le rangement de vos produits.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Ordre des catégories" type={TagType.UPDATE} />
          <Divider />
          <span>Vous pouvez désormais changer l'ordre des catégories d'un catalogue.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Ordre des médias" type={TagType.UPDATE} />
          <Divider />
          <span>Vous pouvez désormais changer l'ordre des médias des produits en cliquant dessus et en les glissant à la position souhaitée.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Corrections de bugs" type={TagType.CORRECTED} />
          <Divider />
          <span>Divers bugs d'affichages de données sur un catalogue ont étés corrigés.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 13/07/2022",
    previews: [<Nametag title="Remonter un bug" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Remonter un bug" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Le bouton{" "}
            <span style={{ fontWeight: 600 }}>
              <FontAwesomeIcon icon={faBug} />
              Remonter un bug
            </span>{" "}
            a été ajouté afin de vous permettre de nous signaler tout problème.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 17/05/2022",
    previews: [<Nametag title="Page d'accueil" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Page d'accueil" type={TagType.NEW} />
          <Divider />
          <span>
            Nos applications disposent désormais d'une page d'accueil. Depuis cette page, vous aurez accès à la liste des mises à jour et à différents
            autres modules en fonction de l'application.
          </span>
          <Divider />
          <span>
            D'autres modules sont en cours de conception, n'hésitez pas à suivre les mises à jour afin d'être au courant de leur avancement.
          </span>
        </li>
      </ul>
    ),
  },
];
