import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import style from "../css/TopBar.module.css";

import { ReactComponent as OnboardingCircle } from "../svg/onboarding-circle.svg";
import { ReactComponent as UserCircle } from "../svg/user-circle.svg";
import { ReactComponent as CaretDown } from "../svg/caret-down.svg";
import { ReactComponent as PowerOff } from "../svg/power-off.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { isAdmin, startOnBoardingTour } from "../../utils/utils";
import { ShepherdOptionsWithType, ShepherdTourContext } from "react-shepherd";
import { createProductSteps } from "../../onboardingtours/create-product.steps";
import { createCategorySteps } from "../../onboardingtours/create-category.steps";
import { detailledCatalogSteps } from "../../onboardingtours/detailled-catalog.steps";
import { useNavigate } from "react-router-dom";
import { detailledProductSteps } from "../../onboardingtours/detailled-product.steps";
import { detailledCategorySteps } from "../../onboardingtours/detailled-category.steps";
import { createCatalogSteps } from "../../onboardingtours/create-catalog.steps";
import { shareCatalogSteps } from "../../onboardingtours/share-catalog.steps";
import { UserContextType, useUser } from "../../context/UserContext";

interface Action {
  text: string;
  onClick: () => void;
}

interface Props {
  username?: string;
  userRole?: string;
  logout?: () => void;
  actions?: Action[];
  brandLogoSrc: string;
  brandLogoClick?: () => void;
  brandLogoText?: string;
}

export default function TopBar(props: Props) {
  const menu = useRef<any>(null);
  const menuButton = useRef<any>(null);

  const onboarding = useRef<any>(null);
  const onboardingButton = useRef<any>(null);
  const [isOnboardingOpen, setIsOnBoardingOpen] = useState<boolean>(false);

  const tour = useContext(ShepherdTourContext);
  const history = useNavigate();
  const [user] = useUser() as UserContextType;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, false);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleOnboarding() {
    setIsOnBoardingOpen((before) => !before);
  }

  const handleOutsideClick = (e: any) => {
    if (menu.current && !menu.current.contains(e.target) && menuButton.current && !menuButton.current.contains(e.target)) {
      toggleMenu();
    }

    if (onboarding.current && !onboarding.current.contains(e.target) && onboardingButton.current && !onboardingButton.current.contains(e.target)) {
      toggleOnboarding();
    }
  };

  function toggleMenu() {
    setIsMenuOpen((before) => !before);
  }

  function handleStartOnboarding(steps: ShepherdOptionsWithType[]) {
    toggleOnboarding();
    history("/");
    startOnBoardingTour(tour, steps);
  }

  return (
    <>
      <div className={style.topbar}>
        <div className={style.brandLogo}>
          <img className={style.brandLogoIcon} src={props.brandLogoSrc} alt="Logo" onClick={props.brandLogoClick} />
          <span className={style.brandLogoText} onClick={props.brandLogoClick}>
            {props.brandLogoText}
          </span>
        </div>
        <div className={style.rightItems}>
          {isAdmin(user) && (
            <div className={style.onboarding} ref={onboardingButton} onClick={toggleOnboarding}>
              <OnboardingCircle className={classNames(style.icon, style.circle)} />
            </div>
          )}
          <div className={style.userInfoContainer}>
            <span className={style.userName}>{props.username}</span>
            <span className={style.userRole}>{props.userRole}</span>
          </div>
          <div className={style.collapseButton} ref={menuButton} onClick={toggleMenu}>
            <UserCircle className={classNames(style.icon, style.userCircle)} />
            <CaretDown
              className={classNames(style.icon, style.caretDown, {
                [style.reverseCaretDown]: isMenuOpen === true,
              })}
            />
          </div>
          {isMenuOpen && (
            <div className={style.menu} ref={menu}>
              {props.actions?.map((action) => (
                <p onClick={action.onClick}>{action.text}</p>
              ))}
              {/* <div className={style.separator}></div> */}
              {props.logout && (
                <p
                  className={style.disconnect}
                  onClick={() => {
                    props.logout && props.logout();
                    setIsOnBoardingOpen(false);
                  }}
                >
                  <PowerOff />
                  Déconnexion
                </p>
              )}
            </div>
          )}

          {isOnboardingOpen && (
            <div className={classNames([style.onboardingMenu])} ref={onboarding}>
              <span className={style.textContent}>Découvrez les tutoriels</span>
              <div className={style.itemList}>
                <div className={style.listItem} onClick={() => handleStartOnboarding(createProductSteps(history))}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Créer un produit
                </div>
                <div className={style.listItem} onClick={() => handleStartOnboarding(detailledProductSteps(history))}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Options d'un produit
                </div>
                <div className={style.listItem} onClick={() => handleStartOnboarding(createCategorySteps(history))}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Créer une catégorie
                </div>
                <div className={style.listItem} onClick={() => handleStartOnboarding(detailledCategorySteps(history))}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Options d'une catégorie
                </div>
                <div className={style.listItem} onClick={() => handleStartOnboarding(createCatalogSteps(history))}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Créer un catalogue
                </div>
                <div className={style.listItem} onClick={() => handleStartOnboarding(detailledCatalogSteps(history))}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Options d'un catalogue
                </div>
                <div className={style.listItem} onClick={() => handleStartOnboarding(shareCatalogSteps(history))}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Partager un catalogue
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={style.fakeTopbar}></div>
    </>
  );
}
