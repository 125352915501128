import React from "react";
import classNames from "classnames";
import style from "../css/Checkbox.module.css";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: React.ReactNode;
  click?: React.MouseEventHandler<HTMLLabelElement>;
  invalid?: boolean;
}

export default function Checkbox(props: Props) {
  return (
    <label
      className={classNames(style.checkbox, {
        [style.invalid]: props.invalid,
      })}
      onClick={props.click}
    >
      <input type="checkbox" {...props} />
      {props.label}
    </label>
  );
}
