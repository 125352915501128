import { host, checkStatus } from "./api";
import { Capture, PageItem, Product, SelectedProduct } from "./_type";
import qs from "qs";

interface CreateProductResponse {
  product: Product;
  selectedProduct: null | {
    id: number;
    product: { id: number };
    category: { id: number };
    position: number;
  };
}

const product = {
  getProducts: ({ page }: { page?: number }): Promise<PageItem<Product>> => {
    return fetch(`${host}/api/products?${qs.stringify({ page })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getOneProduct: (productId: number): Promise<Product> => {
    return fetch(`${host}/api/products/${productId}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  addProduct: ({
    name,
    description,
    priceHt,
    priceTtc,
    category,
    file,
    captures,
  }: {
    name: string;
    description: string;
    priceHt: number | null;
    priceTtc: number | null;
    category?: number;
    file: File[];
    captures: Capture[];
  }): Promise<CreateProductResponse> => {
    return fetch(`${host}/api/products`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        description,
        priceHt,
        priceTtc,
        category,
      }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  uploadImage: ({ productId, images }: { productId: number; images: File[] }): Promise<Product> => {
    if (images === null) throw new Error("No images");

    let data = new FormData();

    for (const image of images) {
      data.append("files[]", image, image.name);
    }

    return fetch(`${host}/api/products/${productId}/file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  deleteProduct: (id: number): Promise<string> => {
    return fetch(`${host}/api/products/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  updateProduct: ({
    name,
    description,
    priceHt,
    priceTtc,
    file,
    productId,
    captures,
  }: {
    name: string;
    description: string;
    priceHt: number | null;
    priceTtc: number | null;
    file: File[];
    productId: number;
    captures: Capture[];
  }): Promise<Product> => {
    return fetch(`${host}/api/products/${productId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        description,
        priceHt,
        priceTtc,
      }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  defineExternalFile: ({ captureId, body }: { captureId: number; body: { fileName: string; mimeType: string }[] }): Promise<Product> => {
    return fetch(`${host}/api/products/${captureId}/external-file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  sendCustomerContact: ({
    id,
    body,
  }: {
    id: number;
    body: { sender: { firstname: string; lastname: string; mail: string; phonenumber: string }; message: string };
  }): Promise<string> => {
    return fetch(`${host}/api/products/${id}/contact`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
};
export default product;
