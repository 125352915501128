import React, { useEffect, useRef, useState } from "react";
import style from "./CreateCategoryComponent.module.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../api/api";
import { Capture, Category, Media, SelectedCategory, SmallCatalogue, SmallCategory, SubCategoryToCreate } from "../../api/_type";
import Winylo from "../../winylo";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ModalGalery from "../ModalGalery/ModalGalery";
import Previewer from "../Previewer/Previewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { MY_DOMAIN, toastify, toFileArray } from "../../utils/utils";
import { useContext } from "react";
import { ShepherdTourContext } from "react-shepherd";
import Modal from "../../winylo/components/Modal";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

interface SubProps {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  subcat: SmallCategory;
  category: Category | undefined;
  formik: any;
  setSubCategoriesToUpdate: React.Dispatch<React.SetStateAction<SmallCategory[]>>;
  handleSubCatRemove: (arrayHelpers: FieldArrayRenderProps, idnex: number, subcat: SmallCategory) => void;
}

function SubCategory(props: SubProps) {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  // useEffect(() => {
  //   if (!props.category?.subCategories.includes(props.subcat)) {
  //     setIsEnabled(true);
  //   }
  // }, []);

  function handlePenClick() {
    isEnabled && props.setSubCategoriesToUpdate((o) => [...o, props.formik.values.subcategories[props.index]]);
    setIsEnabled(!isEnabled);
  }

  return (
    <div className={style.subCatElement} key={props.index}>
      <div className={style.subCatName}>
        <span style={{ fontWeight: 600 }}> </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Winylo.Input
            id={`subcategories.${props.index}`}
            name={`subcategories.${props.index}.name`}
            variant={props.formik.touched.subcategories && props.formik.errors.subcategories ? "invalid" : "normal"}
            className={style.subCatNameInput}
            onChange={props.formik.handleChange}
            onBlur={props.formik.handleBlur}
            disabled={!isEnabled}
            value={props.formik.values.subcategories[props.index].name}
            icon={<></>}
          />
          {!isEnabled ? (
            <FontAwesomeIcon
              title="Modifier la sous-catégorie"
              className={classNames([style.fontAwesomeIcon, style.subCatPen, style.subCatButtons])}
              icon={faPen}
              onClick={() => handlePenClick()}
            />
          ) : (
            <FontAwesomeIcon
              title="Valider"
              className={classNames([style.fontAwesomeIcon, style.subCatPen, style.subCatButtons])}
              icon={faCheck}
              onClick={() => handlePenClick()}
            />
          )}
          <FontAwesomeIcon
            title="Supprimer la sous-catégorie"
            className={classNames([style.fontAwesomeIcon, style.subCatTrash, style.subCatButtons])}
            icon={faTrash}
            onClick={() => props.handleSubCatRemove(props.arrayHelpers, props.index, props.subcat)}
          />
        </div>
        <ErrorMessage name={`subcategories.${props.index}.name`} component="div" className="field-error" />
      </div>
    </div>
  );
}

interface Props {
  idCategory?: string;
  idParent?: string;
  noRedirect?: boolean;
  catalogue?: SmallCatalogue;
  onSubmit?: () => void;
}

export default function CreateCategoryComponent(props: Props) {
  const navigate = useNavigate();
  const tour = useContext(ShepherdTourContext);

  const create = props.idCategory === undefined;

  const { data: category } = useQuery([props.idCategory], () => api.categories.getOneCategory(parseInt(props.idCategory!)), {
    onSuccess: (data) => {
      setMedia(data.media);
    },
    enabled: !create,
  });
  const inputRef = useRef<any>(null);

  const [file, setFile] = useState<File[]>([]);
  const [capture, setCapture] = useState<Capture | null>(null);
  const [modalGaleryOpen, setModalGaleryOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [media, setMedia] = useState<Media | null>(null);
  const [idMediaToDelete, setIdMediaToDelete] = useState<number | undefined>(undefined);

  const [subCategoriesToDelete, setSubCategoriesToDelete] = useState<SmallCategory[]>([]);
  const [subCategoriesToCreate, setSubCategoriesToCreate] = useState<SubCategoryToCreate[]>([]);
  const [subCategoriesToUpdate, setSubCategoriesToUpdate] = useState<SmallCategory[]>([]);

  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState<boolean>(false);

  const [tempNewSubCategory, setTempNewSubCategory] = useState<string>("");

  const [successMsg, setSuccessMsg] = useState("");

  const queryClient = useQueryClient();

  const { mutate: createCategory } = useMutation(api.categories.addCategory, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (result, variable) => {
      // setSuccessMsg("Catégorie ajoutée !");

      props.idParent && queryClient.invalidateQueries("selectedCategories");

      api.categories.addSubCategories({ subcategories: formik.values.subcategories, parent: result.id }).then((newsubs) => {
        if (props.catalogue !== undefined) {
          createSelectedCategory({
            idCatalogue: props.catalogue.id,
            idCategory: result.id,
          });

          formik.resetForm({
            values: initialValues,
          });
          setFile([]);
          setCapture(null);
        }

        if (variable.file !== undefined) {
          api.categories
            .updateImage({
              categoryId: result.id,
              image: variable.file,
            })
            .then((dataImg) => {
              success();
            });
        } else if (variable.capture !== null) {
          api.categories
            .defineExternalFile({
              idCagegorie: result.id,
              fileName: variable.capture.file,
              mimeType: variable.capture.type,
            })
            .then((dataImg) => {
              success();
            });
        } else {
          success();
        }
      });
    },
  });

  const { mutate: updateCategory } = useMutation(api.categories.updateCategory, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (result, variable) => {
      api.categories.deleteCategories(subCategoriesToDelete.map((o_subCategory) => o_subCategory.id)).then((data) => {
        api.categories
          .addSubCategories({
            subcategories: subCategoriesToCreate,
            parent: variable.idCategory,
          })
          .then((data) => {
            api.categories.updateSubCategories({
              subcategories: subCategoriesToUpdate,
              parent: variable.idCategory,
            });
          })
          .finally(() => {
            if (variable.file !== undefined) {
              api.categories
                .updateImage({
                  categoryId: result.id,
                  image: variable.file,
                })
                .then((dataImg) => {
                  success();
                });
            } else if (variable.capture !== null) {
              api.categories
                .defineExternalFile({
                  idCagegorie: result.id,
                  fileName: variable.capture.file,
                  mimeType: variable.capture.type,
                })
                .then((dataImg) => {
                  success();
                });
            } else {
              success();
            }
          });
      });
    },
  });

  const { mutate: createSelectedCategory } = useMutation(api.selectedCategory.addSelectedCategory, {
    onSuccess: (selectedCategory) => {
      queryClient.setQueryData<SelectedCategory[]>("selectedCategories", (old: SelectedCategory[] | undefined) => {
        if (old === undefined) return [];
        return [...old, selectedCategory];
      });

      // queryClient.invalidateQueries("catalog_products"); // inutile vu que la catégorie ne peut pas avoir de produit à la création
    },
  });

  function success() {
    setIsLoading(false);

    if (props.idParent) {
      queryClient.invalidateQueries([props.idParent]);
      // if (props.catalogue) {
      //   queryClient.invalidateQueries("selectedCategories");
      // }
    } else {
      queryClient.invalidateQueries("categories");
    }
    // queryClient.setQueryData<SmallCategory[]>("categories", (old: SmallCategory[] | undefined) => {
    //   if (old === undefined) return [];
    //   return [...old];
    // });

    if (props.catalogue) {
      queryClient.refetchQueries("selectedCategories");
      queryClient.invalidateQueries("catalog_products");
    }

    if (props.noRedirect) {
      formik.resetForm({
        values: initialValues,
      });
      setFile([]);
      setIsModalSuccessOpen(true);
    } else if (props.onSubmit) {
      props.onSubmit();
    } else {
      props.idParent ? navigate(`/categories/${props.idParent}/subcategories`) : navigate("/categories");
    }
  }

  const deleteFile = (file: File[]) => {
    formik.setFieldValue("file", []);
    setFile([]);
  };

  const deleteCapture = (capture: Capture) => {
    formik.setFieldValue("capture", null);
    setCapture(null);
  };

  const hideMedia = (media: Media) => {
    formik.setFieldValue("media", null);
    setIdMediaToDelete(media.id);
    setMedia(null);
  };

  const { mutateAsync: deleteMedia } = useMutation(api.medias.deleteMedia, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (result, idMediaDeleted) => {
      if (media) setMedia(media.id !== idMediaDeleted ? media : null);
      queryClient.invalidateQueries("categories");
      setIsLoading(false);
    },
  });

  function handleSubCatRemove(arrayHelpers: FieldArrayRenderProps, index: number, subcat: SmallCategory) {
    arrayHelpers.remove(index);
    // deleteCategory(subcat.id);
    setSubCategoriesToDelete((o) => [...o, subcat]);
  }

  function onClickChooseFile() {
    if (inputRef.current) inputRef.current.click();
  }

  function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    const mFile = toFileArray(e.currentTarget.files);

    if(mFile && mFile[0].size > 10485760){
      toastify("Le fichier ne peut dépasser 10 mo !");
      e.target.value = "";
      return;
   };

    if (mFile) setFile(mFile);

    formik.setFieldValue("file", mFile);

    capture && deleteCapture(capture);
    media && hideMedia(media);

    inputRef.current.value = "";

    tour?.isActive() && tour.next();
  }

  function handleChooseFromGalery(capture: Capture) {
    setCapture(capture);
    setModalGaleryOpen(false);
    formik.setFieldValue("capture", capture);

    file && deleteFile(file);
    media && hideMedia(media);
  }

  function handleAddNewSubCategory(arrayHelpers: FieldArrayRenderProps) {
    arrayHelpers.push({ name: tempNewSubCategory });
    setSubCategoriesToCreate((o) => [...o, { name: tempNewSubCategory }]);
    setTempNewSubCategory("");
  }

  interface InitialValue {
    name: string;
    description: string;
    file: File[];
    capture: Capture | null;
    subcategories: SmallCategory[];
  }

  const initialValues: InitialValue = {
    name: "",
    description: "",
    file: [],
    capture: null,
    subcategories: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(`Le nom de la ${props.idParent ? "sous-" : ""}catégorie ne peut pas être vide.`),
      description: Yup.string(),
      file: Yup.mixed(),
      capture: Yup.mixed(),
      subcategories: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Le nom de la sous-catégorie ne peux pas être vide."),
        })
      ),
    }),
    onSubmit: (values) => {
      let body = {
        name: values.name,
        description: values.description,
        file: values.file[0] || undefined,
        capture: values.capture || null,
      };

      if (create) {
        if (props.idParent) {
          createCategory({ ...body, parent: parseInt(props.idParent) });
        } else {
          createCategory(body);
        }
      } else {
        if (category?.id !== undefined) {
          updateCategory({ idCategory: category.id, ...body });
        }
      }
    },
  });

  useEffect(() => {
    if (category) {
      formik.resetForm({
        values: {
          name: category.name,
          description: category.description,
          file: [],
          capture: null,
          subcategories: category.subCategories,
        },
      });
    } else {
      formik.resetForm({
        values: initialValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <>
      {successMsg && <p style={{ fontWeight: 700 }}>{successMsg}</p>}
      <div className={props.catalogue && style.container} style={{ display: "flex" }}>
        <form className="onboarding-createcategory-3rd" onSubmit={formik.handleSubmit} style={{ flex: 2 }}>
          <Winylo.Input
            id="name"
            name="name"
            variant={formik.touched.name && formik.errors.name ? "invalid" : "normal"}
            message={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
            label={`Nom de la ${props.idParent ? "sous-" : ""}catégorie*`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            icon={<></>}
          ></Winylo.Input>
          <label className={style.label}>Description</label>

          <ReactQuill
            theme="snow"
            value={formik.values.description}
            onChange={(content) => formik.setFieldValue("description", content)}
            style={{ overflowY: "scroll", resize: "vertical", wordBreak: "break-all" /*, maxWidth: "25.222vw"*/ }}
          />
        </form>
        <div style={{ flex: 2, marginLeft: "3.5714rem", marginRight: "3.5714rem" }}>
          <label className={style.label}>Image / vidéo (max 10mo)</label>
          <input style={{ display: "none" }} type="file" ref={inputRef} onChange={handleFileSelected} accept="image/png, image/jpeg, video/mp4" />

          <div style={{ display: "flex", alignItems: "stretch", justifyContent: "space-between" }}>
            <Winylo.Button
              id="onboarding-createcategory-next-4th"
              className={classNames("onboarding-createcategory-4th", style.chooseFileButton)}
              format="square"
              size="large"
              onClick={onClickChooseFile}
              disabled={isLoading}
            >
              Choisir un fichier
            </Winylo.Button>
            <Winylo.Button
              style={{
                color: "black",
                display: "flex",
                justifyContent: "center",
                padding: 0,
                paddingLeft: "2rem",
                paddingRight: "2rem",
                whiteSpace: "nowrap",
              }}
              format="square"
              size="large"
              variant="white"
              onClick={() => setModalGaleryOpen(true)}
              disabled={isLoading}
            >
              Ouvrir Photos & Vidéos{" "}
              <img
                src={`${MY_DOMAIN}/p&v-logo.png`}
                style={{
                  height: "2.5571rem",
                  width: "2.5571rem",
                }}
              />
            </Winylo.Button>
          </div>
          <Previewer
            files={file}
            captures={capture ? [capture] : []}
            medias={media ? [media] : []}
            deleteFile={deleteFile}
            deleteCapture={deleteCapture}
            deleteMedia={hideMedia}
          />
        </div>
        {!props.idParent && (
          <div className={style.subCatContainer}>
            <FormikProvider value={formik}>
              <span className={style.suCatFormTitle}>{"Ajouter des sous-catégories"}</span>
              <FieldArray
                name="subcategories"
                render={(arrayHelpers) => (
                  <>
                    <div className={classNames(style.subCatFormik, "onboarding-createcategory-5th")}>
                      {formik.values.subcategories &&
                        formik.values.subcategories.length > 0 &&
                        formik.values.subcategories.map((subcat, index) => (
                          <SubCategory
                            index={index}
                            arrayHelpers={arrayHelpers}
                            subcat={subcat}
                            category={category}
                            formik={formik}
                            setSubCategoriesToUpdate={setSubCategoriesToUpdate}
                            handleSubCatRemove={handleSubCatRemove}
                          />
                        ))}
                      <div className={style.subCatElement}>
                        <div className={style.subCatName}>
                          <span style={{ fontWeight: 600 }}>Nouvelle sous-catégorie</span>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Winylo.Input
                              id={`-1`}
                              name={`newSubCategory`}
                              className={style.subCatNameInput}
                              onChange={(e) => setTempNewSubCategory(e.target.value)}
                              onBlur={formik.handleBlur}
                              value={tempNewSubCategory}
                              icon={<></>}
                            />
                            <FontAwesomeIcon
                              title="Ajouter une sous-catégorie"
                              className={classNames([style.fontAwesomeIcon, style.subCatPen, style.subCatButtons])}
                              icon={faPlus}
                              onClick={() => tempNewSubCategory.length > 0 && handleAddNewSubCategory(arrayHelpers)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              />
            </FormikProvider>
          </div>
        )}

        <ModalGalery isOpen={modalGaleryOpen} setIsOpen={setModalGaleryOpen} onChooseOne={handleChooseFromGalery} />
      </div>
      <div>
        <div style={{ textAlign: "center" }}>
          <Winylo.Button
            id="onboarding-createcategory-next-6th"
            className="onboarding-createcategory-6th"
            onClick={() => formik.handleSubmit()}
            style={{ marginTop: "1.875rem" }}
            disabled={isLoading}
          >
            {category === undefined ? `Créer une ${props.idParent ? "sous-" : ""}catégorie` : `Modifier la ${props.idParent ? "sous-" : ""}catégorie`}
          </Winylo.Button>
        </div>
      </div>

      <Modal
        title={`${props.idParent ? "Sous-" : ""}Catégorie ajoutée`}
        isOpen={isModalSuccessOpen}
        onClose={() => setIsModalSuccessOpen(false)}
        closable
      >
        <FontAwesomeIcon icon={faCheckCircle} className={style.confirmIcon} />
        <p className={style.text}>
          {`Votre ${props.idParent ? "sous-" : ""}catégorie a été ajoutée à la liste !`}
          {"Vous pouvez en créer d'autres ou bien fermer la fenêtre de création."}
        </p>
      </Modal>
    </>
  );
}
