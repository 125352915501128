import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils/utils";

export function createProductSteps(history: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Créer un produit</span>`;

  const defaultDelay: number = 100;

  const stepsLength = 5;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "create-product-1st",
      attachTo: { element: ".onboarding-createproduct-1st", on: "right" },
      advanceOn: { selector: ".onboarding-createproduct-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history("/products");
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
        label: "fg",
      },
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
       Cliquez sur l\'onglet <span class="important-text">Produits</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-product-2nd",
      attachTo: { element: ".onboarding-createproduct-2nd", on: "left-end" },
      advanceOn: { selector: ".onboarding-createproduct-2nd", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.getElementById("onboarding-createproduct-next");
            simulateMouseClick(element);
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-2nd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur le bouton <span class="important-text">Créer un produit</span>..`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-product-3rd",
      attachTo: { element: ".onboarding-createproduct-3rd", on: "left-end" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history("/products");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-2nd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
        Remplissez le <span class="important-text">formulaire</span> avec les informations de votre produit.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-product-4th",
      attachTo: { element: ".onboarding-createproduct-4th", on: "left-end" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const elem = document.getElementById("onboarding-createproduct-next-4th");
            simulateMouseClick(elem);
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-2nd",
      highlightClass: "onboarding-createproduct-highlight-4th",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur <span class="important-text">Choisir un fichier</span> pour ajouter des images ou des vidéos à votre produit.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-product-5th",
      attachTo: { element: ".onboarding-createproduct-5th", on: "left-end" },
      advanceOn: { selector: ".onboarding-createproduct-5th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const elem = document.getElementById("onboarding-createproduct-next-5th");
            simulateMouseClick(elem);
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-2nd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur <span class="important-text">Créer un produit</span> pour valider la création de votre produit.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-product-6th",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            history("/?onBoardingStart=1");
            this.complete();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">${title}</span> !<br/>
        <br/>
        Vous pouvez vous arrêter là ou bien continuer<br/>
        en lançant le tutoriel <span class="important-text">"Options d'un produit"</span>.`,
      ],
      when: {
        cancel: function () {
          history("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
