import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ShepherdOptionsWithType, Tour } from "react-shepherd";
import { ROLES, SmallUser, User } from "../api/_type";
import Toastify from "toastify-js";

export const MY_DOMAIN = window.location.protocol + "//" + window.location.hostname + (window.location.port !== "" ? `:${window.location.port}` : "");

export function parseHash() {
  return new URLSearchParams(window.location.hash.substr(1));
}

export function parseQuery() {
  return new URLSearchParams(window.location.search);
}

export function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const patterns = [
  new RegExp(/\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3,8}Z\b/),
  new RegExp(/\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\b/),
  new RegExp(/\b\d{4}-\d{2}-\d{2}s\d{2}:\d{2}:\d{2}\b/),
  new RegExp(/\b\d{4}\/\d{2}\/\d{2}T\d{2}:\d{2}:\d{2}.d{3,8}Z\b/),
  new RegExp(/\b\d{4}\/\d{2}\/\d{2}T\d{2}:\d{2}:\d{2}\b/),
  new RegExp(/\b\d{4}\/\d{2}\/\d{2}s\d{2}:\d{2}:\d{2}\b/),
  new RegExp(/\b\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\b/),
  new RegExp(/\b\d{4}-(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])\b/),
  new RegExp(/\b(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}\b/),
  new RegExp(/\b(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])-\d{4}\b/),

  new RegExp(/\b\d{4}\/(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\b/),
  new RegExp(/\b\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\b/),
  new RegExp(/\b(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}\b/),
  new RegExp(/\b(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}\b/),

  new RegExp(/\b\d{4}.(0[1-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1])\b/),
  new RegExp(/\b\d{4}.(0[1-9]|[1-2][0-9]|3[0-1]).(0[1-9]|1[0-2])\b/),
  new RegExp(/\b(0[1-9]|[1-2][0-9]|3[0-1]).(0[1-9]|1[0-2]).\d{4}\b/),
  new RegExp(/\b(0[1-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1]).\d{4}\b/),

  new RegExp(/\b(?:2[0-3]|[01][0-9]):[0-5][0-9](:[0-5][0-9]).\d{3,6}\b/),
  new RegExp(/\b(?:2[0-3]|[01][0-9]):[0-5][0-9](:[0-5][0-9])\b/),
  new RegExp(/\b(?:2[0-3]|[01][0-9]):[0-5][0-9]\b/),

  new RegExp(/\b(?:1[012]|0[0-9]):[0-5][0-9](:[0-5][0-9]).\d{3,6}\b/),
  new RegExp(/\b(?:1[012]|0[0-9]):[0-5][0-9](:[0-5][0-9])\b/),
  new RegExp(/\b(?:1[012]|0[0-9]):[0-5][0-9]\b/),
];

const format = [
  "YYYY-mm-ddTHH:MM:ss.Z",
  "YYYY-mm-ddTHH:MM:ss",
  "YYYY-mm-dd HH:MM:ss",
  "YYYY/mm/ddTHH:MM:ss.uZ",
  "YYYY/mm/ddTHH:MM:ss",
  "YYYY/mm/dd HH:MM:ss",
  "YYYY-mm-dd",
  "YYYY-dd-mm",
  "dd-mm-YYYY",
  "mm-dd-YYYY",

  "YYYY/dd/mm",
  "YYYY/mm/dd",
  "dd/mm/YYYY",
  "mm/dd/YYYY",

  "YYYY.mm.dd",
  "YYYY.dd.mm",
  "dd.mm.YYYY",
  "mm.dd.YYYY",

  // for 24-hour | hours seconds
  "HH:MM:ss.ms",
  "HH:MM:ss",
  "HH:MM",

  // for 12-hour | hours seconds
  "hh:MM:ss.ms",
  "hh:MM:ss",
  "hh:MM",
];

export function dateToString(d: string | Date): string {
  if (d === "") return "";

  let date: Date;

  if (d instanceof Date) {
    date = d;
  } else {
    date = parseDate(d);
  }

  return `${zero(date.getDate())}/${zero(date.getMonth() + 1)}/${date.getFullYear()}`;
}

export function parseDate(date: string): Date {
  let i = 0;
  let finded = false;

  while (i < patterns.length && !finded) {
    if (date.match(patterns[i])) {
      finded = true;
    } else {
      i++;
    }
  }

  const usedFormat: string = format[i];

  if (!finded) {
    throw new Error("Date invalide");
  }

  let parsedDate = new Date();

  let indexDate = usedFormat.indexOf("YYYY");
  if (indexDate !== -1) {
    parsedDate.setFullYear(parseInt(date.substring(indexDate, indexDate + 4)));
  }

  indexDate = usedFormat.indexOf("mm");
  if (indexDate !== -1) {
    parsedDate.setMonth(parseInt(date.substring(indexDate, indexDate + 2)) - 1);
  }

  indexDate = usedFormat.indexOf("dd");
  if (indexDate !== -1) {
    parsedDate.setDate(parseInt(date.substring(indexDate, indexDate + 2)));
  }

  indexDate = usedFormat.indexOf("HH");
  if (indexDate !== -1) {
    parsedDate.setHours(parseInt(date.substring(indexDate, indexDate + 2)));
  }

  indexDate = usedFormat.indexOf("MM");
  if (indexDate !== -1) {
    parsedDate.setMinutes(parseInt(date.substring(indexDate, indexDate + 2)));
  }

  indexDate = usedFormat.indexOf("ss");
  if (indexDate !== -1) {
    parsedDate.setSeconds(parseInt(date.substring(indexDate, indexDate + 2)));
  }

  return parsedDate;
}

function zero(int: number): string {
  return int < 10 ? "0" + int : "" + int;
}

export function stringToSize(size: string | number) {
  let initial;

  if (typeof size === "string") {
    initial = parseInt(size);
  } else {
    initial = size;
  }

  let reste = initial;

  let extensions = ["o", "ko", "Mo", "Go", "To"];
  let currentExtension = 0;

  while (reste > 1000) {
    currentExtension++;
    reste = Math.trunc(reste / 10) / 100;
  }

  return reste + " " + extensions[currentExtension];
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      reject("Error: " + error);
    };
  });
}

export function formatUserRoleToString(roles: ROLES[]) {
  if (roles.includes(ROLES.ROLE_COMPANY_ADMIN)) {
    return "Admin. Entreprise";
  }

  if (roles.includes(ROLES.ROLE_APPLICATION_ADMIN)) {
    return "Admin. Application";
  }

  return "Utilisateur";
}

export function removeHtmlTags(text: string) {
  let div = document.createElement("div");
  div.innerHTML = text;
  return div.innerText;
}

export function compare<T>(a: T | undefined, b: T | undefined): -1 | 0 | 1 {
  if (!a) return -1;
  if (!b) return 1;

  // Comparaison sans prendre en compte les maj/min
  if (typeof a === "string" && typeof b === "string") {
    let otherA = a.toLowerCase().trim();
    let otherB = b.toLowerCase().trim();

    if (otherA > otherB) {
      return 1;
    } else if (otherA === otherB) {
      return 0;
    } else {
      return -1;
    }
  }

  if (a > b) {
    return 1;
  } else if (a === b) {
    return 0;
  } else {
    return -1;
  }
}

export function useDebounce<T>(initialValue: T, funcToSend: (arg: T) => void, time: number = 500) {
  // Création du state local
  const [localState, setLocalState] = useState<T>(initialValue);
  const [currentTimout, setCurrentTimout] = useState<undefined | NodeJS.Timeout>(undefined);

  // Récupération du changement
  function handleChange(value: T | ((val: T) => T)): void {
    // Change le state local
    const valueToStore = value instanceof Function ? value(localState) : value;
    setLocalState(valueToStore);

    // Si il y a déjà un timout, on l'annule
    if (currentTimout) {
      clearInterval(currentTimout);
    }

    // On crée un nouveau timout
    setCurrentTimout(setTimeout(() => funcToSend(valueToStore), time));
  }

  function simpleChange(value: T | ((val: T) => T)): void {
    const valueToStore = value instanceof Function ? value(localState) : value;
    setLocalState(valueToStore);
  }

  return [localState, handleChange, simpleChange] as const;
}

export function copyStringToClipboard(str: string) {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(str);
  } else {
    var el = document.createElement("textarea");

    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
}

export function toFileArray(fileList: FileList | null): File[] {
  let fileArray: File[] = [];

  if (fileList) {
    for (let i = 0; i < fileList.length; i++) {
      fileArray.push(fileList[i]);
    }
  }

  return fileArray;
}

const mouseClickEvents = ["mousedown", "click", "mouseup"];

export function simulateMouseClick(element: Element | null) {
  if (element === null) {
    console.log("%cCAN'T SIMULATE MOUSE CLICK ON NULL ELEMENT", "color: red");
    return;
  }

  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
}

export function startOnBoardingTour(tour: Tour | null, steps: ShepherdOptionsWithType[], start: boolean = true, startingStep?: string) {
  if (tour) {
    tour.isActive() && tour.cancel();
    tour.steps = [];
    tour.addSteps(steps);
  }

  if (start) {
    tour?.start();
  } else if (startingStep) {
    tour?.show(startingStep);
  }
}

export function formatUserName(user: SmallUser, inversed: boolean = false) {
  return inversed ? `${user.lastname} ${user.firstname}` : `${user.firstname} ${user.lastname}`;
}

export function isVowel(s: string) {
  return /^[aeiou]$/i.test(s);
}

const getIsMobile = () => window.innerWidth <= 768;

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
}

export function toastify(message: string) {
  Toastify({
    text: message,
    duration: message.length > 75 ? 8000 : 3000,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
  }).showToast();
}

export function isAdmin(user: User | undefined): boolean {
  if (user === undefined) return false;

  return user.roles.includes(ROLES.ROLE_COMPANY_ADMIN) || user.roles.includes(ROLES.ROLE_APPLICATION_ADMIN);
}
