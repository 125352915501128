import { host, checkStatus, checkStatusWithoutError } from "./api";
import { Capture, Category, SelectedProduct } from "./_type";

const selectedProduct = {
  addSelectedProduct: ({ idProduct, idCategory }: { idProduct: number; idCategory: number }): Promise<SelectedProduct> => {
    return fetch(`${host}/api/selectedproducts`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ product: idProduct, category: idCategory }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  deleteSelectedProduct: ({ product, category }: { product: number; category: number }): Promise<string | undefined> => {
    return fetch(`${host}/api/selectedproducts/${product}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
  cloneSelectedProduct: ({
    id,
    name,
    description,
    priceHt,
    priceTtc,
    file,
    captures,
  }: {
    id: number;
    name: string;
    description: string;
    priceHt: number | null;
    priceTtc: number | null;
    file: File[];
    captures: Capture[] | null;
  }): Promise<SelectedProduct> => {
    return fetch(`${host}/api/selectedproducts/${id}/clone`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ name, description, priceHt, priceTtc }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  move: ({ id, position }: { id: number; position: number }): Promise<Category> => {
    return fetch(`${host}/api/selectedproducts/${id}/position`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ position }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getSelectedProductsByCatalog: (catalogId: number): Promise<SelectedProduct[]> => {
    return fetch(`${host}/api/catalogues/${catalogId}/selectedproducts`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getSelectedProductsByPublicCatalog: (token: string): Promise<SelectedProduct[]> => {
    return fetch(`${host}/api/catalogues/${token}/selectedproducts`)
      .then(checkStatusWithoutError)
      .then((res) => res.json());
  },
};

export default selectedProduct;
