import React from "react";

import style from "../css/Modal.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Modal, { ModalProps } from "./Modal";

interface Props extends ModalProps {
  text: string;
}

export default function ConfirmModal(props: Omit<Props, "children">) {
  return (
    <Modal {...props}>
      <FontAwesomeIcon icon={faCheckCircle} className={style.confirmIcon} />
      <p className={style.title}></p>
      <p className={style.text}>{props.text}</p>
    </Modal>
  );
}
