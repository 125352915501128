import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { ShepherdTourContext } from "react-shepherd";
import { Product } from "../../api/_type";
import api from "../../api/api";
import Carousel from "../../component/Carousel/Carousel";
import PopOver from "../../component/PopOver/PopOver";
import { ReactComponent as ThreeDotsIcon } from "../../svg/three-dots.svg";
import { MY_DOMAIN, removeHtmlTags } from "../../utils/utils";
import Winylo from "../../winylo";
import carouselStyle from "./Carousel.module.css";
import style from "./ProductList.module.css";

export default function ProductList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const tour = useContext(ShepherdTourContext);

  const [products, setProducts] = useState<Product[]>([]);
  const [productsTotallength, setProductsTotallength] = useState<number>(0);

  const {
    refetch: refetchProducts,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(
    "products",
    ({ pageParam = 1 }) =>
      api.product.getProducts({
        page: pageParam,
      }),
    {
      onSuccess: (data) => {
        setProducts(data.pages.map((page) => page.items).flat());
        setProductsTotallength(data.pages[0].pagination.totalCount);
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    window.addEventListener("scroll", eventHandler);

    function eventHandler() {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= window.innerHeight + 300 && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    }

    return () => {
      window.removeEventListener("scroll", eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  const [productOptionsOpen, setProductOptionsOpen] = useState<Product | undefined>(undefined);
  const [productToDelete, setProductToDelete] = useState<Product | undefined>(undefined);

  const [search, setSearch] = useState<string>("");

  const { mutate: deleteProduct } = useMutation(api.product.deleteProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
    },
  });

  function isInt(n: number) {
    return n % 1 === 0;
  }

  function renderProduct(product: Product) {
    return (
      <div key={`productlist_p_${product.id}`} className={style.productContainer} style={{ position: "relative" }}>
        <Carousel medias={product.medias} style={carouselStyle} unselectedColor="#bdbdbd" selectedColor="#19323c" canZoom thumbnails />
        <div className={style.productContent}>
          <div className={style.productName}>{product.name}</div>
          <div className={style.pricesContent}>
            {
              <span className={style.priceTtc}>
                {product.priceTtc &&
                  (isInt(product.priceTtc)
                    ? product.priceTtc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € TTC"
                    : product.priceTtc
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € TTC")}
              </span>
            }
            {
              <span className={style.priceHt}>
                {product.priceHt &&
                  (isInt(product.priceHt)
                    ? product.priceHt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT"
                    : product.priceHt
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT")}
              </span>
            }
          </div>
          <div className={style.productDescription}>{removeHtmlTags(product.description)}</div>
          <div className={style.productFooter}>
            {/* <Winylo.Button
              size="small"
              variant="yellow"
              format="square"
              className={classNames(style.productPreview, "onboarding-detailledproduct-5th")}
              id="onboarding-detailledproduct-next-5th"
              onClick={() => console.log("VISUALISER PRODUIT", product)}
            >
              Visualiser
            </Winylo.Button> */}
            <div></div>
            <div
              id="onboarding-detailledproduct-next-2nd"
              className={classNames(style.productThreeDots, "onboarding-detailledproduct-2nd")}
              onClick={() => setProductOptionsOpen(product)}
            >
              <ThreeDotsIcon />
            </div>
          </div>
          {productOptionsOpen?.id === product.id && (
            <PopOver
              clickOutside={() => {
                !tour?.isActive() && setProductOptionsOpen(undefined);
              }}
              className={style.popover}
            >
              <div className="onboarding-detailledproduct-3rd" onClick={() => navigate(`/products/${product.id}/edit`)}>
                Modifier
              </div>
              <div className="onboarding-detailledproduct-4th" onClick={() => setProductToDelete(product)}>
                Supprimer
              </div>
            </PopOver>
          )}
        </div>
      </div>
    );
  }

  function confirmDeleteProduct() {
    if (productToDelete) {
      deleteProduct(productToDelete.id);
      setProductToDelete(undefined);
    }
  }

  function getProducts() {
    return products?.filter((product) => product.name.toLowerCase().includes(search.toLowerCase()));
  }

  useEffect(() => {
    document.addEventListener("closePopOver", () => setProductOptionsOpen(undefined), false);
  }, []);

  return (
    <div className={style.container}>
      <Winylo.Card
        className={style.card}
        text={"Produits"}
        leftElements={
          <Link to={"/products/create"}>
            <Winylo.Button id="onboarding-createproduct-next" className="onboarding-createproduct-2nd" variant="blue" format="square">
              Créer un produit
            </Winylo.Button>
          </Link>
        }
      >
        <div className={style.cardHeader}>
          <Winylo.Input
            placeholder="Recherche"
            inputContainerProps={{
              className: style.cardHeaderInput,
            }}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <Winylo.ImportantNumber
            style={{ marginTop: 0, marginLeft: "1.875rem" }}
            number={productsTotallength}
            text={"Produit"}
            textPlural={"Produits"}
          />
        </div>
        {products && products.length > 0 ? (
          <div className={style.productsContainer}>{getProducts()?.map((product) => renderProduct(product))}</div>
        ) : (
          <div className={style.productContainerEmpty} onClick={() => navigate("/products/create")}>
            <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
            <span style={{ marginTop: "1rem" }}>Vous n'avez aucun produit pour le moment.</span>
            <span>Cliquez ici pour en créer un !</span>
          </div>
        )}
      </Winylo.Card>
      <Winylo.Modal title="Supprimer le produit" isOpen={!!productToDelete} onClose={() => setProductToDelete(undefined)}>
        <p>
          Voulez-vous vraiment supprimer le produit <span style={{ fontWeight: 600 }}>{productToDelete?.name}</span> ?
        </p>
        <div className={style.modalDeleteButtons}>
          <Winylo.Button variant="gray" fullWidth onClick={() => setProductToDelete(undefined)}>
            Annuler
          </Winylo.Button>
          <Winylo.Button variant="red" fullWidth onClick={confirmDeleteProduct}>
            Supprimer
          </Winylo.Button>
        </div>
      </Winylo.Modal>
    </div>
  );
}
