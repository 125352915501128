import React from "react";
import classNames from "classnames";
import style from "../css/Textarea.module.css";

interface Props extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
  legend?: React.ReactNode;
  variant?: "normal" | "valid" | "invalid";
  message?: string;
  background?: "gray" | "white";
}

export default function Textarea(props: Props) {
  return (
    <div>
      {!!props.label ? <label className={style.label}>{props.label}</label> : null}
      <textarea
        {...props}
        className={classNames(
          style.textarea,
          props.className,
          style[props.variant || "normal"],
          {
            // [style.hasLegend]: !!props.legend,
            [style.backgroundWhite]: props.background === "white",
          },
          props.disabled && style.readOnly
        )}
      >
        {props.children}
      </textarea>
      {props.message && <p className={classNames(style.message, style[props.variant || "normal"])}>{props.message}</p>}
      {!!props.legend ? <p className={style.legend}>{props.legend}</p> : null}
    </div>
  );
}
