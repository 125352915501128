import { useState } from "react";
import Table from "../../lib/Table/Table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faEye, faPen, faShareAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

import style from "./CatalogsList.module.css";
import { useNavigate } from "react-router-dom";
import { PRICE_TYPE, SmallCatalogue } from "../../api/_type";
import Winylo from "../../winylo";
import ModalShareCatalogue from "../../component/ModalShareCatalogue/ModalShareCatalogue";
import { dateToString, formatUserName, MY_DOMAIN } from "../../utils/utils";
import classNames from "classnames";

function CatalogsList() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [catalogueToDelete, setCatalogueToDelete] = useState<SmallCatalogue | undefined>(undefined);
  const [catalogueToShare, setCatalogueToShare] = useState<SmallCatalogue | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const { data: catalogs } = useQuery("catalogs", api.catalogue.getCatalogues, {
    keepPreviousData: true,
  });

  const { mutate: createCatalogue } = useMutation(api.catalogue.addCatalogue, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("catalogs");
      navigate(`/catalog/${data.id}/edit`);
    },
  });

  const { mutate: deleteCatalogue } = useMutation(api.catalogue.deletecatalogue, {
    onSuccess: () => {
      queryClient.invalidateQueries("catalogs");
    },
  });

  function editCatalog(catalog: SmallCatalogue) {
    navigate(`/catalog/${catalog.id}/edit`);
  }

  function confirmDeleteCatalogue() {
    if (catalogueToDelete) {
      deleteCatalogue(catalogueToDelete.id);
      setCatalogueToDelete(undefined);
    }
  }

  function getCatalogues() {
    return catalogs?.filter((catalog) => catalog.name.toLowerCase().includes(search.toLowerCase()));
  }

  function clickCreateCatalogue() {
    createCatalogue({
      name: "Catalogue",
      description: "",
      priceType: PRICE_TYPE.BOTH,
      capture: null,
    });
  }

  function handleShare(event: any, catalog: SmallCatalogue) {
    event.stopPropagation();
    setCatalogueToShare(catalog);
  }

  function handleOpen(event: any, catalog: SmallCatalogue) {
    event.stopPropagation();
    navigate(`/c/${catalog.publicToken}`);
  }

  function handleEdit(event: any, catalog: SmallCatalogue) {
    event.stopPropagation();
    editCatalog(catalog);
  }

  function handleClone(event: any, catalog: SmallCatalogue) {
    event.stopPropagation();
  }

  function handleDelete(event: any, catalog: SmallCatalogue) {
    event.stopPropagation();
    setCatalogueToDelete(catalog);
  }

  return (
    <div className={style.container}>
      <Winylo.Card
        className={style.card}
        text={"Catalogues"}
        leftElements={
          <Winylo.Button
            id="onboarding-createcatalog-1st"
            className="onboarding-createcatalog-1st"
            variant="blue"
            format="square"
            onClick={() => clickCreateCatalogue()}
          >
            Créer un catalogue
          </Winylo.Button>
        }
      >
        <div className={style.cardHeader}>
          <Winylo.Input
            placeholder="Recherche"
            inputContainerProps={{
              className: style.cardHeaderInput,
            }}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <Winylo.ImportantNumber
            style={{ marginTop: 0, marginLeft: "1.875rem" }}
            number={catalogs?.length || 0}
            text={"Catalogue"}
            textPlural={"Catalogues"}
          />
        </div>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          {/* <Winylo.SortMenu title="Recherche avancée">
            <Winylo.SortMenuRow title="Catalogues">
              <Winylo.Input background="white" />
            </Winylo.SortMenuRow>
            <Winylo.SortMenuRow title="Auteur">
              <Winylo.Input background="white" />
            </Winylo.SortMenuRow>
            <Winylo.SortMenuRow title="Date de création">
              <Winylo.Input background="white" />
            </Winylo.SortMenuRow>
          </Winylo.SortMenu> */}
          {catalogs && catalogs.length > 0 ? (
            <div style={{ /*marginLeft: "1.875rem",*/ flex: "1 1", textAlign: "left" }}>
              <Table>
                <thead>
                  <tr>
                    <th>Catalogues</th>
                    <th>Description</th>
                    <th>Créé le</th>
                    <th>Créateur</th>
                    <th style={{ width: "140px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {getCatalogues()?.map((catalog, index) => (
                    <tr key={`cataloglist_l_${index}`} onClick={() => navigate(`/catalog/${catalog.id}/edit`)} className={style.catalogRow}>
                      <td className={style.catalogName}>
                        <div
                          className={style.catalogImage}
                          style={{ backgroundImage: "url('" + (catalog.media?.uri || process.env.PUBLIC_URL + "/no_media.jpg") + "')" }}
                        ></div>
                        <span>{catalog.name}</span>
                      </td>
                      <td>{catalog.description}</td>
                      <td>{catalog.createdAt ? dateToString(catalog.createdAt) : "Non renseigné"}</td>
                      <td>{catalog.createdBy ? formatUserName(catalog.createdBy) : "Non renseigné"}</td>
                      {/* <td>-</td> */}
                      <td>
                        <div className={style.iconsColumn}>
                          <div className={style.actionIconContainer} title="Partager" onClick={(event) => handleShare(event, catalog)}>
                            <FontAwesomeIcon
                              id="onboarding-sharecatalog-2nd"
                              className={classNames(style.actionIcon, "onboarding-detailledcatalog-2nd", "onboarding-sharecatalog-2nd")}
                              icon={faShareAlt}
                            />
                          </div>

                          <div className={style.actionIconContainer} title="Ouvrir" onClick={(event) => handleOpen(event, catalog)}>
                            <FontAwesomeIcon className={classNames(style.actionIcon, "onboarding-detailledcatalog-3rd")} icon={faEye} />
                          </div>

                          <div className={style.actionIconContainer} title="Modifier" onClick={(event) => handleEdit(event, catalog)}>
                            <FontAwesomeIcon className={classNames(style.actionIcon, "onboarding-detailledcatalog-4th")} icon={faPen} />
                          </div>

                          {/* <div className={style.actionIconContainer} title="Dupliquer">
                            <FontAwesomeIcon className={style.actionIcon} icon={faClone} onClick={(event) => handleClone(event, catalog)} />
                          </div> */}

                          <div className={style.actionIconContainer} title="Supprimer" onClick={(event) => handleDelete(event, catalog)}>
                            <FontAwesomeIcon className={classNames(style.actionIcon, "onboarding-detailledcatalog-5th")} icon={faTrash} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className={style.catalogContainerEmpty} onClick={() => clickCreateCatalogue()}>
              <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
              <span style={{ marginTop: "1rem" }}>Vous n'avez aucun catalogue pour le moment.</span>
              <span>Cliquez ici pour en créer un !</span>
            </div>
          )}
        </div>
      </Winylo.Card>
      <Winylo.Modal title="Supprimer le catalogue" isOpen={!!catalogueToDelete} onClose={() => setCatalogueToDelete(undefined)}>
        <p>
          Voulez-vous vraiment supprimer le catalogue <span style={{ fontWeight: 600 }}>{catalogueToDelete?.name}</span> ?
        </p>
        <div className={style.modalDeleteButtons}>
          <Winylo.Button variant="gray" fullWidth onClick={() => setCatalogueToDelete(undefined)}>
            Annuler
          </Winylo.Button>
          <Winylo.Button variant="red" fullWidth onClick={confirmDeleteCatalogue}>
            Supprimer
          </Winylo.Button>
        </div>
      </Winylo.Modal>
      {catalogueToShare && (
        <ModalShareCatalogue isOpen={!!catalogueToShare} onClose={() => setCatalogueToShare(undefined)} catalogue={catalogueToShare} />
      )}
    </div>
  );
}

export default CatalogsList;
