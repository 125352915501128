import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils/utils";

export function detailledCatalogSteps(history: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Options d'un catalogue</span>`;

  const classes: string = "winylo-onboarding";

  const defaultDelay: number = 50;

  const stepsLength = 5;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "detailled-catalog-1st",
      attachTo: { element: ".onboarding-detailledcatalog-1st", on: "right" },
      advanceOn: { selector: ".onboarding-detailledcatalog-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history("/catalogs");
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur l\'onglet <span class="important-text">Catalogues</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-catalog-2nd",
      attachTo: { element: ".onboarding-detailledcatalog-2nd", on: "left" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
        En cliquant sur l’icône <span class="important-text-black">Partager</span> <img src="${MY_DOMAIN}/onboarding/share-alt-solid.svg" width="14" height="14"/>, vous pouvez partager votre rubrique<br/>
        sous forme de <span class="important-text">QR code</span> ou alors de <span class="important-text">lien URL</span>.<br/>
        <br/>
        Plus de détail dans le prochain tutoriel <span class="important-text">Partager un catalogue</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-catalog-3rd",
      attachTo: { element: ".onboarding-detailledcatalog-3rd", on: "left" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
        L’icône <span class="important-text-black">Visualiser</span> <img src="${MY_DOMAIN}/onboarding/eye-solid.svg" width="14" height="14"/> vous renvoie vers le<br/>
        <span class="important-text">catalogue</span> et vous permet de vous mettre à la place des utilisateurs.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-catalog-4th",
      attachTo: { element: ".onboarding-detailledcatalog-4th", on: "left" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
        L’icône <span class="important-text-black">Modifier</span> <img src="${MY_DOMAIN}/onboarding/pen-solid.svg" width="14" height="14"/> vous permet d'éditer<br/>
        votre <span class="important-text">catalogue</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-catalog-5th",
      attachTo: { element: ".onboarding-detailledcatalog-5th", on: "left" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        <br/>
        Enfin, l'icône <span class="important-text-black">Poubelle</span> <img src="${MY_DOMAIN}/onboarding/trash-solid.svg" width="14" height="14"/> vous permet de<br/>
        supprimer votre <span class="important-text">catégorie</span>.<br/>
        <br/>
        <span class="important-text-red">Attention, c'est définitif !</span>`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-catalog-5th",
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            history("/?onBoardingStart=6");
            this.complete();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">${title}</span> !<br/>
        <br/>
        Vous pouvez vous arrêter là ou bien continuer<br/>
        en lançant le tutoriel <span class="important-text">"Partager un catalogue"</span>.`,
      ],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
