import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect, MouseEventHandler, ReactNode } from "react";
import { Media } from "../../api/_type";
import { useIsMobile } from "../../utils/utils";

interface Props {
  children?: ReactNode;
  onSwipe?: (direction: number) => void; // -1 = "onSwipeLeft" && +1 = "onSwipeRight" && 0 = "onSwipeCancelled"
  canSwipeRight?: boolean;
  canSwipeLeft?: boolean;
}

export default function SwipeItem({ canSwipeRight = true, canSwipeLeft = true, ...props }: Props) {
  const [touchStart, setTouchStart] = useState<number>(0);
  const [touchEnd, setTouchEnd] = useState<number>(0);
  const [touchDistance, setTouchDistance] = useState<number>(0);

  const minSwipeDistance = 100;

  function onTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    setTouchEnd(0);
    setTouchStart(e.targetTouches[0].clientX);
  }

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const isLeftSwipe = touchDistance > minSwipeDistance;
    const isRightSwipe = touchDistance < -minSwipeDistance;

    if (isLeftSwipe || isRightSwipe) {
      props.onSwipe && props.onSwipe(isRightSwipe ? 1 : -1);
    } else {
      props.onSwipe && props.onSwipe(0);
    }

    setTouchEnd(0);
    setTouchStart(0);
    setTouchDistance(0);
  };

  useEffect(() => {
    const distance = touchStart - touchEnd;

    if (distance < 0 && canSwipeRight) {
      setTouchDistance(distance);
    } else if (distance > 0 && canSwipeLeft) {
      setTouchDistance(distance);
    }
  }, [touchEnd]);

  return (
    <>
      <div style={{ overflowX: "hidden" }} onTouchStart={(e) => onTouchStart(e)} onTouchMove={(e) => onTouchMove(e)} onTouchEnd={() => onTouchEnd()}>
        <div
          style={{
            transform: `translateX(${-touchDistance}px)`,
          }}
        >
          {props.children}
        </div>
      </div>
    </>
  );
}
