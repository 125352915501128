import Button from "./components/Button";
import Card from "./components/Card";
import Modal from "./components/Modal";
import ConfirmModal from "./components/ConfirmModal";
import TopBar from "./components/TopBar";
import SortMenu from "./components/SortMenu";
import SortMenuRow from "./components/SortMenuRow";
import Input from "./components/Input";
import InputFile from "./components/InputFile";
import Checkbox from "./components/Checkbox";
import Select from "./components/Select";
import ProgressBar from "./components/ProgressBar";
import ImportantNumber from "./components/ImportantNumber";
import Textarea from "./components/Textarea";
import SidePannel from "./components/SidePannel";
import Separator from "./components/Separator";
import Breadcrumb from "./components/Breadcrumb";
import Tabs from "./components/Tabs";

const Winylo = {
  Button,
  Card,
  Modal,
  ConfirmModal,
  TopBar,
  SortMenu,
  SortMenuRow,
  Input,
  InputFile,
  Checkbox,
  Select,
  ProgressBar,
  ImportantNumber,
  Textarea,
  SidePannel,
  Separator,
  Breadcrumb,
  Tabs,
};

export default Winylo;
