import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./context/UserContext";
import { HasCheckLoginContextProvider } from "./context/HasCheckLoginContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "./winylo/css/index.css";
import "./index.css";
import "cropperjs/dist/cropper.css";
import "./cropper.css";
import { ShepherdTour } from "react-shepherd";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
  console.log = function () {};

  // Initialize Sentry
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAY_RATE ?? "0.1"),
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: process.env.REACT_APP_VERSION ?? "0.0.0",
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
      label: "fg",
    },
  },
  useModalOverlay: true,
  exitOnEsc: true,
  keyboardNavigation: false,
};

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <HasCheckLoginContextProvider>
        <QueryClientProvider client={queryClient}>
          <ShepherdTour steps={[]} tourOptions={tourOptions}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ShepherdTour>
        </QueryClientProvider>
      </HasCheckLoginContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
