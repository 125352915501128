import React from "react";
import classNames from "classnames";

import style from "../css/Button.module.css";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: "blue" | "yellow" | "red" | "gray" | "green" | "white";
  size?: "large" | "small" | "xs";
  loading?: boolean;
  format?: "round" | "square";
  fullWidth?: boolean;
}

export default function Button(props: Props) {
  return (
    <button
      {...props}
      disabled={props.disabled || props.loading || false}
      className={classNames(
        style.button,
        style[props.variant || "blue"],
        style[props.size || "large"],
        style[props.format || "round"],
        { [style.fullWidth]: props.fullWidth === true },
        props.className
      )}
    >
      {props.loading && <i className={classNames(style.loader, "fa fa-circle-o-notch")} />}
      {props.children}
    </button>
  );
}
