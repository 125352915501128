import { useState } from "react";
import { ErrorMessage, useFormik } from "formik";
import { useMutation } from "react-query";
import * as Yup from "yup";
import api from "../../api/api";
import Winylo from "../../winylo";
import style from "./CustomerContactForm.module.css";

interface Props {
  selectedCatalogueId?: number;
  selectedProductId?: number;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
  setIsSaveConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InitialValue {
  firstname: string;
  lastname: string;
  mail: string;
  phonenumber: string;
  message: string;
}

const initialValues: InitialValue = {
  firstname: "",
  lastname: "",
  mail: "",
  phonenumber: "",
  message: "",
};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export function CustomerContactForm(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      firstname: Yup.string().required("Veuillez indiquer votre prénom."),
      lastname: Yup.string().required("Veuillez indiquer votre nom."),
      mail: Yup.string().email("Veuillez indiquer une adresse mail valide !").required("Veuillez indiquer votre adresse mail."),
      phonenumber: Yup.string()
        .matches(phoneRegExp, "Veuillez indiquer un numéro de téléphone valide !")
        .required("Veuillez indiquer votre numéro de téléphone."),
      message: Yup.string(),
    }),
    onSubmit: (values) => {
      let body = {
        sender: {
          firstname: values.firstname,
          lastname: values.lastname,
          mail: values.mail,
          phonenumber: values.phonenumber,
        },
        message: values.message,
      };

      props.selectedCatalogueId && sendCatalogueCustomerContact({ id: props.selectedCatalogueId, body });
      props.selectedProductId && sendProductCustomerContact({ id: props.selectedProductId, body });
    },
  });

  const { mutate: sendCatalogueCustomerContact } = useMutation(api.catalogue.sendCustomerContact, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (res) => {
      formik.resetForm();
      props.setSelectedId(-1);
      props.setIsSaveConfirmed(true);
      setIsLoading(false);
    },
  });

  const { mutate: sendProductCustomerContact } = useMutation(api.product.sendCustomerContact, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (res) => {
      formik.resetForm();
      props.setSelectedId(-1);
      props.setIsSaveConfirmed(true);
      setIsLoading(false);
    },
  });

  return (
    <Winylo.Card className={style.card}>
      <div className={style.titleContainer}>
        <span className={style.title}>Veuillez remlir le formulaire</span>
        <span className={style.subtitle}>Vos informations personnelles ne seront pas publiées.</span>
      </div>
      <form onSubmit={formik.handleSubmit} style={{ flex: 2 }}>
        <div className={style.inputsContainer}>
          <span className={style.inputLabel}>Prénom</span>
          <Winylo.Input
            name="firstname"
            className={style.firstnameInput}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            value={formik.values.firstname}
            icon={<></>}
            isInvalid={formik.touched.firstname && !!formik.errors.firstname}
            isInvalidMessage={formik.touched.firstname && !!formik.errors.firstname ? formik.errors.firstname : ""}
          />
          <span className={style.inputLabel}>Nom</span>
          <Winylo.Input
            name="lastname"
            className={style.lastnameInput}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            value={formik.values.lastname}
            icon={<></>}
            isInvalid={formik.touched.lastname && !!formik.errors.lastname}
            isInvalidMessage={formik.touched.lastname && !!formik.errors.lastname ? formik.errors.lastname : ""}
          />
          <span className={style.inputLabel}>Adresse mail</span>
          <Winylo.Input
            name="mail"
            className={style.mailInput}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            value={formik.values.mail}
            icon={<></>}
            isInvalid={formik.touched.mail && !!formik.errors.mail}
            isInvalidMessage={formik.touched.mail && !!formik.errors.mail ? formik.errors.mail : ""}
          />
          <span className={style.inputLabel}>Téléphone</span>
          <Winylo.Input
            name="phonenumber"
            className={style.phonenumberInput}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            value={formik.values.phonenumber}
            icon={<></>}
            isInvalid={formik.touched.phonenumber && !!formik.errors.phonenumber}
            isInvalidMessage={formik.touched.phonenumber && !!formik.errors.phonenumber ? formik.errors.phonenumber : ""}
          />
          <span className={style.inputLabel}>Informations complémentaires</span>
          <Winylo.Textarea
            name="message"
            className={style.messageInput}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            value={formik.values.message}
          />

          <div></div>
          <div>
            <Winylo.Button type="button" onClick={() => formik.handleSubmit()} disabled={isLoading}>
              Envoyer
            </Winylo.Button>
          </div>
        </div>
      </form>
    </Winylo.Card>
  );
}
