import React from "react";

import style from "../css/Input.module.css";

import classNames from "classnames";
import { ReactComponent as SearchIcon } from "../svg/search.svg";
import { ReactComponent as Xmark } from "../svg/xmark-solid.svg";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: React.ReactNode;
  legend?: React.ReactNode;
  variant?: "normal" | "valid" | "invalid";
  message?: string;
  inverted?: boolean;
  background?: "gray" | "white";
  onClear?: () => void;
  inputContainerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  icon?: React.ReactNode | null;
  isInvalid?: boolean;
  isInvalidMessage?: string;
}

export default function Input({
  label,
  legend,
  variant,
  message,
  inverted,
  background,
  onClear,
  inputContainerProps,
  icon,
  isInvalid,
  isInvalidMessage,
  ...other
}: Props) {
  // const {label, legend, variant, message, inverted, background, onClear, icon, inputContainerProps, ...rest} = props;

  function clearButton() {
    return (
      onClear !== undefined &&
      other.value &&
      other.value.toString().length > 0 && <Xmark title={"Vider le champ"} className={style.clearIcon} onClick={onClear} />
    );
  }

  return (
    <>
      {!!label ? <label className={style.label}>{label}</label> : null}

      <div
        {...inputContainerProps}
        className={classNames(
          style.inputContainer,
          style[variant || "normal"],
          {
            [style.hasLegend]: !!legend,
            [style.inverted]: inverted === true,
            [style.backgroundWhite]: background === "white",
          },
          inputContainerProps?.className,
          isInvalid && style.isInvalid
        )}
      >
        {inverted && clearButton()}
        <input {...other} />
        {!inverted && clearButton()}
        {icon === undefined ? <SearchIcon className={style.searchIcon} /> : icon}
        {!!isInvalidMessage ? <p className={style.isInvalidText}>{isInvalidMessage}</p> : null}
      </div>

      {message && <p className={classNames(style.message, style[variant || "normal"])}>{message}</p>}
      {!!legend ? <p className={style.legend}>{legend}</p> : null}
    </>
  );
}
