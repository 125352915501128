import style from "./SubCategoriesList.module.css";
import Winylo from "../../winylo";
import { Link, useNavigate, useParams } from "react-router-dom";
import CategoryListComponent from "../../component/CategoryListComponent/CategoryListComponent";
import GoBack from "../../component/GoBack/GoBack";
import { SmallCatalogue } from "../../api/_type";
import catalogue from "../../api/catalogue";

interface Props {
  id?: string;
  setSelectedTab?: (index: number) => void;
  catalogue?: SmallCatalogue;
}

export default function SubCategoriesList(props: Props) {
  let { id } = useParams<{ id: string }>();

  if (props.id) id = props.id;

  const navigate = useNavigate();

  return (
    <>
      {!props.id ? (
        <div className={style.container}>
          <Winylo.Card
            className={style.card}
            text={"Sous-catégories"}
            leftElements={
              <Link to={`/categories/create?parent=${id}`}>
                <Winylo.Button variant="blue" format="square">
                  Créer une sous-catégorie
                </Winylo.Button>
              </Link>
            }
          >
            <GoBack text="Retour à la liste des catégories" onClick={() => navigate("/categories")} />
            <CategoryListComponent parentId={id} />
          </Winylo.Card>
        </div>
      ) : (
        <div className={style.containerInModal}>
          <CategoryListComponent parentId={id} setSelectedTab={props.setSelectedTab} catalogue={props.catalogue} />
        </div>
      )}
    </>
  );
}
