import { host, checkStatus, checkStatusWithoutError } from "./api";

import { Catalogue, SmallCatalogue, PublicCatalogue, Capture, PRICE_TYPE, SelectedCategory } from "./_type";

const catalogue = {
  addCatalogue: ({
    name,
    description,
    priceType,
    file,
    capture,
  }: {
    name: string;
    description: string;
    priceType: PRICE_TYPE;
    file?: File[];
    capture: Capture | null;
  }): Promise<SmallCatalogue> => {
    return fetch(`${host}/api/catalogues`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ name, description, priceType }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getCatalogues: (): Promise<SmallCatalogue[]> => {
    return fetch(`${host}/api/catalogues`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getOneCatalogue: (id: number): Promise<Catalogue> => {
    return fetch(`${host}/api/catalogues/${id}?web=true`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  updateCatalogue: ({
    catalogueId,
    name,
    description,
    priceType,
    color,
    file,
    capture,
    showPhone,
    showMail,
    showFacebook,
    showTwitter,
    showLinkedin,
    showInstagram,
    showWebsite,
    showCompanyInfos,
    showQRcode,
    phone,
    mail,
    facebook,
    twitter,
    linkedin,
    instagram,
    website,
    companyInfos,
    isContactable,
    isActive,
  }: {
    catalogueId: number;
    name?: string;
    description?: string;
    priceType?: PRICE_TYPE;
    color?: string;
    file?: File[];
    capture?: Capture | null;
    showPhone?: boolean | null;
    showMail?: boolean | null;
    showFacebook?: boolean | null;
    showTwitter?: boolean | null;
    showLinkedin?: boolean | null;
    showInstagram?: boolean | null;
    showWebsite?: boolean | null;
    showCompanyInfos?: boolean | null;
    showQRcode?: boolean | null;
    phone?: string | null;
    mail?: string | null;
    facebook?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    instagram?: string | null;
    website?: string | null;
    companyInfos?: string | null;
    isContactable?: boolean | null;
    isActive?: boolean | null;
  }): Promise<Catalogue> => {
    return fetch(`${host}/api/catalogues/${catalogueId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({
        name,
        description,
        priceType,
        color,
        showPhone,
        showMail,
        showFacebook,
        showTwitter,
        showLinkedin,
        showInstagram,
        showWebsite,
        showCompanyInfos,
        showQRcode,
        phone,
        mail,
        facebook,
        twitter,
        linkedin,
        instagram,
        website,
        companyInfos,
        isContactable,
        isActive,
      }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  updateImage: ({ catalogueId, image }: { catalogueId: number; image: File }): Promise<Catalogue> => {
    let data = new FormData();
    data.append("file", image);

    return fetch(`${host}/api/catalogues/${catalogueId}/file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  updateBanner: ({ catalogueId, image }: { catalogueId: number; image: File }): Promise<Catalogue> => {
    let data = new FormData();
    data.append("file", image);

    return fetch(`${host}/api/catalogues/${catalogueId}/banner-file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getPublicCatalogue: (token: string): Promise<PublicCatalogue> => {
    return fetch(`${host}/api/catalogues/${token}`)
      .then(checkStatusWithoutError)
      .then((res) => res.json());
  },
  defineExternalFile: ({ idCatalogue, fileName, mimeType }: { idCatalogue: number; fileName: string; mimeType: string }): Promise<Catalogue> => {
    return fetch(`${host}/api/catalogues/${idCatalogue}/external-file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ fileName, mimeType }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  defineBannerExternalFile: ({
    idCatalogue,
    fileName,
    mimeType,
  }: {
    idCatalogue: number;
    fileName: string;
    mimeType: string;
  }): Promise<Catalogue> => {
    return fetch(`${host}/api/catalogues/${idCatalogue}/banner-external-file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ fileName, mimeType }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deletecatalogue: (idCatalogue: number): Promise<string> => {
    return fetch(`${host}/api/catalogues/${idCatalogue}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  getSelectedCategoriesByCatalog: (catalogId: number): Promise<SelectedCategory[]> => {
    return fetch(`${host}/api/catalogues/${catalogId}/selectedcategories`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getSelectedCategoriesByPublicCatalog: (token: string): Promise<SelectedCategory[]> => {
    return fetch(`${host}/api/catalogues/${token}/selectedcategories`)
      .then(checkStatusWithoutError)
      .then((res) => res.json());
  },
  sendCustomerContact: ({
    id,
    body,
  }: {
    id: number;
    body: { sender: { firstname: string; lastname: string; mail: string; phonenumber: string }; message: string };
  }): Promise<string> => {
    return fetch(`${host}/api/catalogues/${id}/contact`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
};

export default catalogue;
