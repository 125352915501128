import login from "./login";
import catalogue from "./catalogue";
import categories from "./categories";
import selectedProduct from "./selectedProduct";
import product from "./product";
import medias from "./medias";
import captures from "./captures";
import selectedCategory from "./selectedCategory";
import bugs from "./bugs";
import companies from "./companies";

import Toastify from "toastify-js";

export const url_core: string = process.env.REACT_APP_URL_CORE || "https://qualif.winylo.fr";
export const host_core: string = process.env.REACT_APP_HOST_CORE || "https://api.qualif.winylo.fr";
export const host: string = process.env.REACT_APP_HOST_API || "https://api.catalogue.qualif.c-koya.tech";

export const checkStatus = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 401) {
      window.location.href = "/login#redirect=" + window.location.pathname;
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    } else {
      return res.json().then((data) => {
        let message = typeof data.message === "object" ? Object.values(data.message).join("\n") : data.message;
        Toastify({
          text: message,
          duration: message.length > 75 ? 8000 : 3000,
          close: true,
          gravity: "bottom", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
        }).showToast();

        throw new Error(data.msg);
      });
    }
  }
};

export const checkStatusQuiet = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    return res.text().then((msg: string) => {
      throw new Error(msg);
    });
  }
};

export const checkStatusWithoutError = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 401) {
      window.location.href = "/login#redirect=" + window.location.pathname;
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    } else if (res.status === 403) {
      return res.json().then((error: any) => {
        window.location.href = `/error#code=${error.code}&message=${error.message}`;
        throw new Error(error);
      });
    } else {
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    }
  }
};

export const parseFormData = (obj: { [key: string]: any }) => {
  let keys = Object.keys(obj);
  let bodyFormData = new FormData();

  keys.forEach((k) => {
    if (obj[k] === null) return;
    bodyFormData.set(k, obj[k]);
  });

  return bodyFormData;
};

const api = {
  loginApi: login,
  catalogue,
  categories,
  selectedProduct,
  product,
  medias,
  captures,
  selectedCategory,
  bugs,
  companies,
};

export default api;
