import { useParams } from "react-router-dom";
import Catalog from "../Catalog/Catalog";
import { ReactComponent as PoweredByIcon } from "./powered-by.svg";
import { useQuery } from "react-query";
import api from "../../api/api";
import { Catalogue, PublicCatalogue } from "../../api/_type";
import { useState } from "react";
import { ReactComponent as FacebookIcon } from "../../svg/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../svg/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../svg/linkedin.svg";
import { ReactComponent as InstagramIcon } from "../../svg/instagram.svg";
import { ReactComponent as WebsiteIcon } from "../../svg/website.svg";
import { ReactComponent as CompanyInfosIcon } from "../../svg/companyInfos.svg";
import Winylo from "../../winylo";

import style from "./CatalogPublic.module.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { CustomerContactForm } from "../../component/CustomerContactForm/CustomerContactForm";

export default function CatalogPublic() {
  const { id, token } = useParams<{ id: string; token: string }>();

  const [publicCatalog, setPublicCatalog] = useState<PublicCatalogue | undefined>(undefined);
  const [isModalContactOpen, setIsModalContactOpen] = useState<number>(-1);
  const [isSaveConfirmed, setIsSaveConfirmed] = useState<boolean>(false);

  useQuery("catalog", () => api.catalogue.getPublicCatalogue(token!), { onSuccess: (data) => setPublicCatalog(data) });

  function getCatalog(currentCatalog?: PublicCatalogue): JSX.Element {
    if (currentCatalog === undefined) return <></>;
    if (!currentCatalog.isActive) {
      return (
        <div
          style={{
            display: "flex",
            margin: "auto",
            maxWidth: "93.75rem",
            height: "100%",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <>
            <div style={{ height: "4rem" }}></div>
            <Winylo.Card text={undefined} className={style.public}>
              <div style={{ marginBottom: ".625rem", height: "2.2679rem", display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <div className={style.catalogInfo} style={{ color: "#323232" }}>
                  <span style={{ fontWeight: "600" }}>
                    {currentCatalog.phone &&
                      ((
                        <span className={style.icon} onClick={() => window.open(`tel:${currentCatalog.phone}`)}>
                          {currentCatalog.phone}
                        </span>
                      ) ||
                        "Téléphone")}{" "}
                    {currentCatalog.phone && currentCatalog.mail && "|"}{" "}
                    {currentCatalog.mail &&
                      ((
                        <span className={style.icon} onClick={() => window.open(`mailto:${currentCatalog.mail}`)}>
                          {currentCatalog.mail}
                        </span>
                      ) ||
                        "Mail")}
                  </span>

                  {currentCatalog.facebook && (
                    <FacebookIcon
                      color={`#${currentCatalog.color || "323232"}`}
                      title="Facebook"
                      className={style.icon}
                      onClick={() => window.open(currentCatalog.facebook!)}
                    />
                  )}
                  {currentCatalog.twitter && (
                    <TwitterIcon
                      color={`#${currentCatalog.color || "323232"}`}
                      title="Twitter"
                      className={style.icon}
                      onClick={() => {
                        window.open(currentCatalog.twitter!);
                      }}
                    />
                  )}
                  {currentCatalog.linkedin && (
                    <LinkedinIcon
                      color={`#${currentCatalog.color || "323232"}`}
                      title="Linkedin"
                      className={style.icon}
                      onClick={() => window.open(currentCatalog.linkedin!)}
                    />
                  )}
                  {currentCatalog.instagram && (
                    <InstagramIcon
                      color={`#${currentCatalog.color || "323232"}`}
                      title="Instagram"
                      className={style.icon}
                      onClick={() => window.open(currentCatalog.instagram!)}
                    />
                  )}
                  {currentCatalog.website && (
                    <WebsiteIcon
                      color={`#${currentCatalog.color || "323232"}`}
                      title="Site personnalisé"
                      className={style.icon}
                      onClick={() => window.open(currentCatalog.website!)}
                    />
                  )}
                </div>
              </div>

              <div style={{ position: "relative" }}>
                <div className={classNames(style.catalogBanner, currentCatalog.banner === null && style.empty)}>
                  {currentCatalog.banner === null ? (
                    <FontAwesomeIcon icon={faCamera} className={style.cameraBanner} />
                  ) : currentCatalog.banner.mimeType.includes("video") ? (
                    <div className={style.catalogBannerVideo}>
                      <video autoPlay loop muted style={{ width: "100%", height: "100%", position: "absolute", top: 0 }}>
                        <source src={currentCatalog.banner.uri} />
                      </video>
                    </div>
                  ) : (
                    <div className={style.bannerImage} style={{ backgroundImage: `url(${currentCatalog.banner.uri})` }}></div>
                  )}
                </div>
                <div
                  className={classNames(style.catalogLogo, currentCatalog.media === null && style.empty)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {currentCatalog.media === null ? (
                    <div className={style.cameraLogoContainer}>
                      <FontAwesomeIcon icon={faCamera} className={style.cameraLogo} />
                    </div>
                  ) : currentCatalog.media.mimeType.includes("video") ? (
                    <video autoPlay loop muted style={{ width: "100%" }}>
                      <source src={currentCatalog.media.uri} />
                    </video>
                  ) : (
                    <div className={style.logoImage} style={{ backgroundImage: `url(${currentCatalog.media.uri})` }}></div>
                  )}
                </div>
              </div>
              <div className={style.catalogueContent}>
                <div
                  style={{
                    flex: 1,
                    minWidth: "300px",
                    paddingRight: "2.0714rem",
                    borderRight: "2px solid var(--border-color)",
                  }}
                >
                  <div className={style.catalogNameContainer}>
                    <div className={style.catalogNameView}>
                      <span className={style.catalogName} style={{ color: `#${currentCatalog.color?.replaceAll("#", "")}` }}>
                        {currentCatalog.name}
                      </span>
                    </div>

                    <div className={style.catalogInfosIcons}>
                      {(currentCatalog.showCompanyInfos || currentCatalog.showQRcode) && (
                        <CompanyInfosIcon
                          color={`#${currentCatalog.color || "323232"}`}
                          title="Informations de l'entreprise"
                          className={style.icon}
                        />
                      )}
                    </div>
                  </div>
                  <Winylo.Separator />
                  <div className={style.contactContainer} style={{ marginLeft: "28px", marginRight: "-2.0714rem" }}>
                    {currentCatalog.isContactable && (
                      <Winylo.Button
                        className={style.contactButton}
                        style={{ backgroundColor: `#${currentCatalog.color}` }}
                        format="square"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsModalContactOpen(currentCatalog.id);
                        }}
                      >
                        Prendre contact
                      </Winylo.Button>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    flex: 2,
                    paddingLeft: "2.4286rem",
                  }}
                >
                  <div className={style.productsContainerHeader}>
                    Ce catalogue a été rendu inactif par son propriétaire. Vous ne pouvez plus le consulter.
                  </div>
                  <div className={classNames(style.productsContainer, "onboarding-createcatalog-10th")}>
                    Néanmoins, vous pouvez toujours prendre contact avec celui-ci via les divers liens partagés.
                  </div>
                </div>
              </div>
            </Winylo.Card>

            <Winylo.Modal
              isOpen={!!currentCatalog.isContactable && isModalContactOpen > -1}
              onClose={() => setIsModalContactOpen(-1)}
              modalStyle={{
                content: {
                  width: "100%",
                  maxWidth: "50.75rem",
                  height: "fit-content",
                  maxHeight: "95%",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
              titlePosition={"left"}
              title="Prendre contact"
              childrenContainerStyle={{ display: "flex", flexDirection: "column", overflow: "auto" }}
            >
              <CustomerContactForm
                selectedCatalogueId={isModalContactOpen}
                setSelectedId={setIsModalContactOpen}
                setIsSaveConfirmed={setIsSaveConfirmed}
              />
            </Winylo.Modal>

            <Winylo.ConfirmModal
              isOpen={isSaveConfirmed}
              onClose={() => setIsSaveConfirmed(!isSaveConfirmed)}
              closable
              text={"Votre demande de contact a bien été envoyée !"}
              titlePosition={"left"}
              title={"Demande de contact"}
            />
          </>
        </div>
      );
    }

    return <Catalog publicCatalog={currentCatalog} />;
  }

  return (
    <span style={{ width: "100vw", height: "100vh", backgroundColor: "white", overflow: "auto" }}>
      {getCatalog(publicCatalog)}
      {/* <PoweredByIcon style={{ position: "fixed", right: "2rem", bottom: "2rem" }} /> */}
    </span>
  );
}
