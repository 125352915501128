import { useEffect } from "react";
import { MY_DOMAIN } from "../../utils/utils";

const urls = {
  ios: "https://apps.apple.com/fr/app/catalogue-digital-winylo/id1589031585",
  android: "https://play.google.com/store/apps/details?id=com.winylo.catalogue",
  unknown: MY_DOMAIN,
};

export default function RedirectToStore() {
  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "android";
    } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) {
      return "ios";
    }
    return "unknown";
  };

  useEffect(() => {
    window.location.href = urls[getMobileOS()];
  });
  return <></>;
}
