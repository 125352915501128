import { host, checkStatus } from "./api";
import { SelectedCategory } from "./_type";

const selectedCategory = {
  addSelectedCategory: ({ idCatalogue, idCategory }: { idCatalogue: number; idCategory: number }): Promise<SelectedCategory> => {
    return fetch(`${host}/api/selectedcategories`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ catalogue: idCatalogue, category: idCategory }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  deleteSelectedCategory: ({
    selectedCategoryId,
    linkedCategoryId,
  }: {
    selectedCategoryId: number;
    linkedCategoryId: number;
  }): Promise<string | undefined> => {
    return fetch(`${host}/api/selectedcategories/${selectedCategoryId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
  updateSelectedCategoryPosition: ({
    selectedCategory,
    newPosition: position,
  }: {
    selectedCategory: SelectedCategory;
    newPosition: number;
  }): Promise<SelectedCategory> => {
    return fetch(`${host}/api/selectedcategories/${selectedCategory.id}/position`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        position,
      }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
};

export default selectedCategory;
