import React, { useRef, useState } from "react";

import style from "../css/InputFile.module.css";

import classNames from "classnames";
import { ReactComponent as Xmark } from "../svg/xmark-solid.svg";
import Button from "./Button";
import { toastify, toFileArray } from "../../utils/utils";
interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: React.ReactNode;
  buttonText?: string;
  legend?: React.ReactNode;
  variant?: "normal" | "valid" | "invalid";
  message?: string;
  inverted?: boolean;
  background?: "gray" | "white";
  onClear?: () => void;
  inputContainerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  maxFileSize?: number;
}

export default function InputFile({
  label,
  buttonText,
  legend,
  variant,
  message,
  inverted,
  background,
  onClear,
  inputContainerProps,
  maxFileSize = 10485760,
  ...other
}: Props) {
  const inputRef = useRef<any>(null);

  const [files, setFiles] = useState<File[]>([]);

  function clearButton() {
    // TODO modifier pour gérer les fichiers
    return (
      onClear !== undefined &&
      files &&
      files.length > 0 && (
        <Xmark
          title={"Vider le champ"}
          className={style.clearIcon}
          onClick={() => {
            setFiles([]);
          }}
        />
      )
    );
  }

  function onClick() {
    if (inputRef.current) inputRef.current.click();
  }

  function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    const files = toFileArray(e.target.files);

    if(maxFileSize && files && files[0].size > maxFileSize){
      toastify("Le fichier ne peut dépasser 10 mo !");
      e.target.value = "";
      return;
   };

    setFiles(files);
    if (other.onChange) other.onChange(e);
  }

  function getFilesNames() {
    if (files === null || files.length === 0) return "Aucun fichier sélectionné";
    let text = files[0].name;
    for (let i = 1; i < files.length; i++) {
      text += ", " + files[i].name;
    }
    return text;
  }

  return (
    <div>
      {!!label ? <label className={style.label}>{label}</label> : null}
      <div
        {...inputContainerProps}
        className={classNames(
          style.inputContainer,
          style[variant || "normal"],
          {
            [style.hasLegend]: !!legend,
            [style.inverted]: inverted === true,
          },
          inputContainerProps?.className
        )}
      >
        <Button type="button" variant="yellow" className={style.buttonChooseFile} onClick={onClick}>
          {buttonText || "Sélectionner"}
        </Button>

        <div
          className={classNames(style.filename, {
            [style.backgroundWhite]: background === "white",
          })}
        >
          {getFilesNames()} {clearButton()}
        </div>

        <input {...other} style={{ display: "none" }} type="file" ref={inputRef} onChange={handleFileSelected} />
      </div>
      {message && <p className={classNames(style.message, style[variant || "normal"])}>{message}</p>}
      {!!legend ? <p className={style.legend}>{legend}</p> : null}
    </div>
  );
}
