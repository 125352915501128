import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils/utils";

export function detailledProductSteps(history: any, setOnBoardingStart?: (index: number) => void): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Options d'un produit</span>`;

  const classes: string = "winylo-onboarding onboarding-detailledproduct";

  const defaultDelay: number = 500;

  const stepsLength = 4; // 5 étapes mais une cachée pour le moment car fonctionnalité non présente

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "detailled-product-1st",
      attachTo: { element: ".onboarding-detailledproduct-1st", on: "right" },
      advanceOn: { selector: ".onboarding-detailledproduct-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history("/products");
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur l’onglet <span class="important-text">Produits</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-product-2nd",
      attachTo: { element: ".onboarding-detailledproduct-2nd", on: "bottom" },
      advanceOn: { selector: ".onboarding-detailledproduct-2nd", event: "click" },
      // canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.getElementById("onboarding-detailledproduct-next-2nd");
            element?.click();
          },
        },
      ],
      classes,
      highlightClass: "onboarding-detailledproduct-2nd-hightlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur l’icône <span class="important-text-black">Options</span> <img src="${MY_DOMAIN}/onboarding/three-dots.svg" width="14" height="14"/>`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-product-3rd",
      attachTo: { element: ".onboarding-detailledproduct-3rd", on: "bottom" },
      // advanceOn: { selector: ".onboarding-detailledproduct-next-3rd", event: "click" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            document.dispatchEvent(new Event("closePopOver"));
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "onboarding-detailledproduct-3rd-hightlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
        Vous pouvez modifier votre produit en cliquant ici..`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-product-4th",
      attachTo: { element: ".onboarding-detailledproduct-4th", on: "bottom" },
      // advanceOn: { selector: ".onboarding-detailledproduct-next-4th", event: "click" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            document.dispatchEvent(new Event("closePopOver"));
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "onboarding-detailledproduct-4th-hightlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
        Vous pouvez supprimer un produit en cliquant ici.<br/>
        <br/>
        <span class="important-text-red">Attention, c'est définitif !</span>`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: "detailled-product-5th",
    //   attachTo: { element: ".onboarding-detailledproduct-5th", on: "bottom" },
    //   // advanceOn: { selector: ".onboarding-detailledproduct-next-5th", event: "click" },
    //   canClickTarget: false,
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         window.scrollTo(0, 0);
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: "shepherd-button shepherd-button-back",
    //       text: backText,
    //       action() {
    //         var element = document.getElementById("onboarding-detailledproduct-next-2nd");
    //         element?.click();
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: "shepherd-button shepherd-button-next",
    //       text: nextText,
    //       action() {
    //         var element = document.getElementById("onboarding-detailledproduct-next-5th");
    //         simulateMouseClick(element);
    //       },
    //     },
    //   ],
    //   classes,
    //   highlightClass: "onboarding-detailledproduct-5th-hightlight",
    //   scrollTo: false,
    //   title,
    //   text: [
    //     `<span class="important-text">5 sur ${stepsLength}</span><br/>
    //     <br/>
    //     Enfin, cliquez sur <span class="important-text-yellow">Visualiser</span> pour voir la page de détails de votre produit.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    // }, /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// cachée pour le moment car fonctionnalité non présente
    {
      id: "detailled-product-6th",
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            history("/?onBoardingStart=2");
            this.complete();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">${title}</span> !<br/>
        <br/>
        Vous pouvez vous arrêter là ou bien continuer
        en lançant le tutoriel <span class="important-text">"Créer une catégorie"</span>.
        `,
      ],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
