import style from "./Error.module.css";
import Winylo from "../../winylo";
import { parseHash } from "../../utils/utils";
import { useEffect, useState } from "react";

export default function Error() {
  const [code, setCode] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    let hash = parseHash();

    setCode(hash.get("code"));
    setMessage(hash.get("message"));
  }, []);

  return (
    <Winylo.Card customCssModule={style} text={`Erreur ${code!} : ${message!}`}>
      <div className={style.cardHeader}>
        <img className={style.image} src={require("./winytriste.png")} alt="Erreur" />
      </div>
    </Winylo.Card>
  );
}
