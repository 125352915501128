import Winylo from "../../winylo";
import { AwesomeQRCode } from "@awesomeqr/react";
import { SmallCatalogue } from "../../api/_type";
import { copyStringToClipboard, MY_DOMAIN } from "../../utils/utils";
import style from "./ModalShareCatalogue.module.css";
import { useEffect, useRef, useState } from "react";
import Toastify from "toastify-js";
import api from "../../api/api";
import { UserContextType, useUser } from "../../context/UserContext";

interface Props {
  catalogue: SmallCatalogue;
  isOpen: boolean;
  onClose: () => void;
}

export default function ModalShareCatalogue({ catalogue, isOpen, onClose }: Props) {
  const catalogueUrl = `${MY_DOMAIN}/c/${catalogue.publicToken}`;
  const [qrOptions, setQrOptions] = useState<{ [key: string]: any }>({});

  const [user] = useUser() as UserContextType;

  function copyString() {
    copyStringToClipboard(catalogueUrl);

    Toastify({
      text: "Adresse copiée dans le presse papier",
      duration: 1500,
      close: true,
      gravity: "bottom", // `top` or `bottom`
      position: "center", // `left`, `center` or `right`
      style: { background: "linear-gradient(to right, #00b09b, #96c93d)" },
    }).showToast();
  }

  // Gestion du téléchargement
  const qrRef = useRef<HTMLDivElement | null>(null);

  function downloadQR() {
    let a = document.createElement("a");
    let qr = qrRef.current?.children[0].children[0] as HTMLDivElement;
    a.href = qr.style.backgroundImage.replace('url("', "").replace('")', "");

    a.download = `QRCode_${catalogue.name}.png`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => {
    api.companies
      .getBrandImage(catalogue.company.id)
      .then((blob) => {
        setQrOptions({
          text: catalogueUrl,
          colorLight: "#ffffff",
          dotScale: 1,
          logoImage: URL.createObjectURL(blob),
          logoScale: 0.3,
          logoCornerRadius: 50,
          margin: 0,
        });
      })
      .catch((err) => {
        setQrOptions({
          text: catalogueUrl,
          colorLight: "#ffffff",
          logoScale: 0.3,
          logoCornerRadius: 50,
          margin: 0,
        });
      });
  }, [catalogue, user]);

  return (
    <>
      <Winylo.Modal
        xMarkID="onboarding-catalog-share-close"
        title="Partager le catalogue"
        isOpen={isOpen}
        onClose={onClose}
        closable
        modalStyle={{ content: { width: "35.7143rem" } }}
      >
        <div className="onboarding-sharecatalog-3rd">
          {/* <QRCodeCategory category={category} qrRef={qrRef} /> */}
          <p className={style.name}>{catalogue.name}</p>
          <div style={{ height: "21.4286rem" }}>
            <div ref={qrRef} style={{ width: "100%", height: "100%" }}>
              <AwesomeQRCode options={qrOptions} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Winylo.Button format="square" fullWidth={false} onClick={downloadQR} style={{ marginTop: "1rem" }}>
              Télécharger le QR Code
            </Winylo.Button>
            {/* <Winylo.Button format="square" fullWidth={false} onClick={handleSaveAndDownload} style={{ marginLeft: "5px" }}>
              Télécharger le PDF
            </Winylo.Button> */}
          </div>
          <div className={style.inputAndButton}>
            <Winylo.Input value={catalogueUrl} inputContainerProps={{ style: { marginBottom: "0px", flex: 1 } }} />
            <Winylo.Button variant="yellow" format="square" onClick={copyString}>
              Copier le lien
            </Winylo.Button>
          </div>
          {/* <Winylo.Button variant="gray" fullWidth={false} format="square" onClick={onClose}>
            Fermer
          </Winylo.Button> */}

          {/* <Winylo.Button format="square" fullWidth={false} onClick={handleSave} style={{ marginLeft: "auto" }}>
            Enregistrer
          </Winylo.Button> */}
        </div>
      </Winylo.Modal>
    </>
  );
}
