import { checkStatus, host } from "./api";
import { Capture, FilterType, PageItem } from "./_type";
import qs from "qs";

const CaptureSortType = {
  [FilterType.DATE_RECENT]: {
    sort: "m.creationDate",
    direction: "DESC",
  },
  [FilterType.DATE_OLD]: {
    sort: "m.creationDate",
    direction: "ASC",
  },
};

const captures = {
  getCaptures: ({ page, sort }: { page: number; sort?: FilterType }): Promise<PageItem<Capture>> => {
    return fetch(`${host}/api/captures?${qs.stringify({ page, ...CaptureSortType[sort || FilterType.DATE_RECENT] })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => {
        let result: any = res.json();

        return result;
      });
  },
};

export default captures;
