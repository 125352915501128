import { checkStatusQuiet, host, host_core } from "./api";
import { User } from "./_type";

const companies = {
  getBrandImage: (companyId: number): Promise<Blob> => {
    return fetch(`${host_core}/api/companies/brand-image/${companyId}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatusQuiet)
      .then((res) => res?.blob());
  },
};

export default companies;
