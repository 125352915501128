import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils/utils";

export function createCatalogSteps(history: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Créer un catalogue</span>`;

  const classes: string = "winylo-onboarding";

  const defaultDelay: number = 50;

  const stepsLength = 12;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "create-catalog-0th",
      attachTo: { element: ".onboarding-createcatalog-0th", on: "right" },
      advanceOn: { selector: ".onboarding-createcatalog-0th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history("/catalogs");
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur l'onglet <span class="important-text">Catalogues</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-1st",
      attachTo: { element: ".onboarding-createcatalog-1st", on: "right" },
      advanceOn: { selector: ".onboarding-createcatalog-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const elem = document.getElementById("onboarding-createcatalog-1st");
            simulateMouseClick(elem);
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur le bouton <span class="important-text">Créer un catalogue</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-2nd",
      attachTo: { element: ".onboarding-createcatalog-2nd", on: "top" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay * 5);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history("/catalogs");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
        Voici le catalogue que vous venez de créer.<br/>
        Pour le moment, il est encore vide. Mais nous allons voir comment le personnaliser !`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-3rd",
      attachTo: { element: ".onboarding-createcatalog-3rd", on: "right" },
      advanceOn: { selector: ".onboarding-createcatalog-3rd", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-3rd");
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur le bouton <span class="important-text">Paramétrage</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-4th",
      attachTo: { element: ".onboarding-createcatalog-4th", on: "left" },
      advanceOn: { selector: ".onboarding-createcatalog-save-4th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-parameters-close");
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-parameters-save");
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        <br/>
        Vous pouvez choisir les couleurs et l’affichage des prix depuis ici,<br/>
        cliquez ensuite sur <span class="important-text">Enregistrer</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-5th",
      attachTo: { element: ".onboarding-createcatalog-5th", on: "top" },
      advanceOn: { selector: ".onboarding-createcatalog-5th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-3rd");
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-5th");
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez sur l’icône <span class="important-text-black">Modifier</span> <img src="${MY_DOMAIN}/onboarding/pen-solid.svg" width="14" height="14"/><br/>
        pour changer le nom de votre catalogue.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-6th",
      attachTo: { element: ".onboarding-createcatalog-6th", on: "top" },
      advanceOn: { selector: ".onboarding-createcatalog-editname-check", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            document.dispatchEvent(new Event("cancel-action-createcatalog6"));
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-editname-check");
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">7 sur ${stepsLength}</span><br/>
        <br/>
        Validez en cliquant sur l’icône <span class="important-text-black">Valider</span> <img src="${MY_DOMAIN}/onboarding/check-solid.svg" width="14" height="14"/>`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-7th",
      attachTo: { element: ".onboarding-createcatalog-7th", on: "top" },
      advanceOn: { selector: ".onboarding-createcatalog-7th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-5th");
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-7th");
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">8 sur ${stepsLength}</span><br/>
        <br/>
        Appuyez sur <span class="important-text">Gérer les catégories</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-8th",
      attachTo: { element: ".onboarding-createcatalog-8th", on: "top" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay * 5);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            const element = document.getElementById("onboarding-catalogselectcategory-back");
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-catalogselectcategory-back");
            simulateMouseClick(element);
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">9 sur ${stepsLength}</span><br/>
        <br/>
        Cochez les catégories que vous souhaitez ajouter à votre <span class="important-text">catalogue</span>.<br/>
        Ensuite, passez à la prochaine étape en appuyant sur <span class="important-text-black">Suivant</span> <img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="16" height="16"/>`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: 0,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-9th",
      attachTo: { element: ".onboarding-createcatalog-9th", on: "top" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-7th");
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">10 sur ${stepsLength}</span><br/>
        <br/>
        Vos catégories sélectionnées apparaissent ici,<br/>
        vous pouvez choisir leurs positions dans la liste<br/>
        et cliquer sur l’une d’entre elle pour visualiser<br/>
        ses produits.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-10th",
      attachTo: { element: ".onboarding-createcatalog-10th", on: "top" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">11 sur ${stepsLength}</span><br/>
        <br/>
        Les produits sont affichés ici, il vous est possible<br/>
        de cliquer sur <span class="important-text">Plus d’infos</span> pour voir la page de<br/>
        détail d’un d’entre eux.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-11th",
      attachTo: { element: ".onboarding-createcatalog-11th", on: "top" },
      advanceOn: { selector: ".onboarding-createcatalog-11th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-createcatalog-11th");
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">12 sur ${stepsLength}</span><br/>
        <br/>
        Enfin, cliquez sur <span class="important-text-yellow">Terminer</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-catalog-12th",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            history("/?onBoardingStart=5");
            this.complete();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">${title}</span> !<br/>
        <br/>
        Vous pouvez vous arrêter là ou bien continuer<br/>
        en lançant le tutoriel <span class="important-text">"Options d'un catalogue"</span>.`,
      ],
      when: {
        cancel: function () {
          history("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
