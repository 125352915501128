import style from "./CategoryList.module.css";
import Winylo from "../../winylo";
import { Link } from "react-router-dom";
import CategoryListComponent from "../../component/CategoryListComponent/CategoryListComponent";

export default function CategoryList() {
  return (
    <div className={style.container}>
      <Winylo.Card
        className={style.card}
        text={"Catégories"}
        leftElements={
          <Link id="onboarding-createcategory-next-2nd" className="onboarding-createcategory-2nd" to={"/categories/create"}>
            <Winylo.Button variant="blue" format="square">
              Créer une catégorie
            </Winylo.Button>
          </Link>
        }
      >
        <CategoryListComponent />
      </Winylo.Card>
    </div>
  );
}
