import { useEffect, useRef, useState } from "react";
import defaultStyle from "./Previewer.module.css";
import classNames from "classnames";
import { Capture, Media } from "../../api/_type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getBase64 } from "../../utils/utils";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";

const type = "Image";

interface ImgProps {
  source: string;
  isVideo: boolean;
  onDelete: () => void;
  style: any;
  id: number;
  changePosition?: (idA: number, idB: number) => void;
  draggable?: boolean;
}
function Image({ source, isVideo, onDelete, style, id, changePosition, draggable = false }: ImgProps) {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: type,
    drop(item: any) {
      if (!ref.current) return;

      const dragId = item.id;
      const hoverId = id;

      if (dragId === hoverId) {
        return;
      }

      //function updateposition
      changePosition && changePosition(item.id, id);

      item.index = hoverId;
    },
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: type,
    item: { id: id, index: id },
    collect: (monitor) => {
      return {
        isDragging: !!monitor.isDragging(),
      };
    },
  }));

  if (draggable) drag(drop(ref));

  return (
    <div
      className={classNames(style.mediaContainer, draggable && style.onHoverMoving, "file-item")}
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: draggable ? "move" : "default",
      }}
    >
      {isVideo ? (
        <div className={style.filePreview}>
          <video className={style.videoPreview} controls>
            <source src={source} />
          </video>
          {onDelete && <FontAwesomeIcon title="Supprimer le média" icon={faTrash} className={style.trashDeleteMedia} onClick={() => onDelete()} />}
        </div>
      ) : (
        <div className={style.filePreview} style={{ backgroundImage: `url('${source}')` }}>
          {onDelete && <FontAwesomeIcon title="Supprimer le média" icon={faTrash} className={style.trashDeleteMedia} onClick={() => onDelete()} />}
        </div>
      )}
    </div>
  );
}

interface Props {
  files?: File[];
  captures?: Capture[];
  medias?: Media[];
  deleteFile?: (file: any) => void;
  deleteCapture?: (capture: Capture) => void;
  deleteMedia?: (media: Media) => void;
  changePosition?: (indexA: number, indexB: number) => void;
  style?: any;
}

export default function Previewer({ files, captures, medias, deleteFile, deleteCapture, deleteMedia, changePosition, style }: Props) {
  const [preview, setPreview] = useState<any[]>([]);

  if (style === undefined) {
    style = defaultStyle;
  }

  async function generateImageToPreview() {
    let length: number = 0;
    let testas: any[] = [];

    if (files !== undefined && files.length > 0 && deleteFile !== undefined) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i] as File;
        await getBase64(file).then((base64: string) => {
          testas.push(
            <div key={file.name + i}>
              <Image source={base64} isVideo={file.type.includes("video")} onDelete={() => deleteFile(file)} style={style} id={i} />
            </div>
          );
        });
      }
      length += files.length;
    }

    if (captures !== undefined && captures.length > 0 && deleteCapture !== undefined) {
      for (let i = 0; i < captures.length; i++) {
        let capture = captures[i] as Capture;

        testas.push(
          <div key={capture.file}>
            <Image
              source={capture.uri}
              isVideo={capture.type.includes("video")}
              onDelete={() => deleteCapture(capture)}
              style={style}
              id={length + i}
            />
          </div>
        );
      }
      length += captures.length;
    }

    if (medias !== undefined && medias.length && deleteMedia !== undefined) {
      for (let i = 0; i < medias.length; i++) {
        let media = medias[i] as Media;

        testas.push(
          <div key={media.id.toString()}>
            <Image
              source={media.uri}
              isVideo={media.mimeType.includes("video")}
              onDelete={() => deleteMedia(media)}
              style={style}
              id={media.id}
              changePosition={changePosition}
              draggable
            />
          </div>
        );
      }
    }

    setPreview(testas);
  }

  useEffect(() => {
    generateImageToPreview();
  }, [files, captures, medias]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classNames(style.filePreviewContainer, preview.length > 0 ? null : style.empty, "file-list")}>{preview}</div>
    </DndProvider>
  );
}
