import React, { useState } from "react";

import style from "../css/SidePannel.module.css";

import classNames from "classnames";

import { ReactComponent as BurgerOpen } from "../svg/burger-open.svg";
import { ReactComponent as BurgerClose } from "../svg/burger-close.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "react-query";
import api from "../../api/api";
import Winylo from "..";
import { UserContextType, useUser } from "../../context/UserContext";

interface Action {
  className?: string;
  text: string;
  path?: string;
  onClick?: () => void;
  icon?: any;
}
interface ActionGroup {
  text: string;
  actions: Action[];
}

interface Props {
  children?: React.ReactElement;
  actions?: ActionGroup[];
}

export default function SidePannel(props: Props) {
  const [user] = useUser() as UserContextType;

  const location = useLocation();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isBugOpen, setIsBugOpen] = useState(false);

  const [bugFormValue, setBugFormValue] = useState<string>("");

  // const [selectedAction, setSelectedAction] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { mutate: sendBug } = useMutation(api.bugs.sendBug, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      setBugFormValue("");
      setIsBugOpen(false);
      setIsLoading(false);
    },
  });

  function handleCancelBugForm() {
    setBugFormValue("");
    setIsBugOpen(false);
  }

  function handleSubmitBugForm() {
    sendBug({ userId: user?.id, message: bugFormValue });
  }

  function onClick(action: Action) {
    if (action.path !== undefined) navigate(action.path);
    if (action.onClick !== undefined) action.onClick();
  }

  function renderActionGroup() {
    return props.actions?.map((group, index) => (
      <div key={`sidepannel_ag_${index}`}>
        <p className={style.actionTitle}>{group.text}</p>
        {group.actions.map((action, index2) => {
          return (
            <div
              className={classNames(
                style.action,
                {
                  [style.selectedRow]: location.pathname === action.path,
                },
                action.className
              )}
              onClick={() => onClick(action)}
              key={`sidepannel_ag_${index}_${index2}`}
            >
              <span className={style.iconContainer}>{action.icon}</span>
              <span className={style.actionText}>{action.text}</span>
            </div>
          );
        })}
      </div>
    ));
  }

  return (
    <div className={style.container}>
      <div className={classNames(style.left, { [style.open]: isMenuOpen })}>
        {isMenuOpen ? (
          <BurgerClose title="Fermer" className={style.openCloseIcon} onClick={() => setIsMenuOpen((before) => !before)} />
        ) : (
          <BurgerOpen title="Ouvrir" className={style.openCloseIcon} onClick={() => setIsMenuOpen((before) => !before)} />
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>{renderActionGroup()}</div>
        <div className={classNames(style.action)} onClick={() => setIsBugOpen(true)}>
          <span className={style.iconContainer}>
            <FontAwesomeIcon icon={faBug} className={style.icon} />
          </span>
          <span className={style.actionText}>Remonter un bug</span>
        </div>
      </div>
      <div className={classNames(style.right, { [style.open]: isMenuOpen })}>{props.children}</div>

      <Winylo.Modal title="Remonter un bug" isOpen={isBugOpen} onClose={() => setIsBugOpen(false)}>
        <div className={style.bugFormContainer}>
          {/* <Utils.Input label="Problème"></Utils.Input> */}
          <Winylo.Textarea label="Description du problème" value={bugFormValue} onChange={(e) => setBugFormValue(e.target.value)}></Winylo.Textarea>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: ".7143rem" }}>
            <Winylo.Button variant="gray" fullWidth={false} format="square" onClick={() => handleCancelBugForm()}>
              Annuler
            </Winylo.Button>
            <Winylo.Button loading={isLoading} format="square" fullWidth={false} onClick={() => handleSubmitBugForm()}>
              Envoyer
            </Winylo.Button>
          </div>
        </div>
      </Winylo.Modal>
    </div>
  );
}
