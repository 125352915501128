import classNames from "classnames";
import React from "react";
import ReactModal from "react-modal";

import style from "../css/Modal.module.css";

import { ReactComponent as Xmark } from "../svg/xmark-solid.svg";

export interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  title?: string;
  titlePosition?: "left" | "center";
  titleColor?: string;
  onClose?: () => void;
  closable?: boolean;
  modalStyle?: ReactModal.Styles;
  childrenContainerStyle?: React.CSSProperties;
  xMarkID?: string;
}

export default function Modal(props: ModalProps) {
  return (
    <ReactModal
      className={style.modal}
      overlayClassName={style.overlay}
      isOpen={props.isOpen}
      onRequestClose={props.closable !== false ? props.onClose : undefined}
      style={props.modalStyle}
      ariaHideApp={false}
    >
      {props.title && (
        <>
          <div
            className={classNames(
              style.titleContainer,
              props.titlePosition === undefined || props.titlePosition === "center" ? style.titleCenter : style.titleLeft
            )}
          >
            <div></div>
            <div className={style.title} style={{ color: props.titleColor ? props.titleColor : "" }}>
              {props.title}
            </div>
            {props.closable !== false && <Xmark id={props.xMarkID} className={style.close} onClick={props.onClose} />}
          </div>
          <div className={style.separator}></div>
        </>
      )}
      <div style={props.childrenContainerStyle}>{props.children}</div>
    </ReactModal>
  );
}
