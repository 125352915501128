import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./CreateProductComponent.module.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../api/api";
import { Capture, Category, Media, SmallCategory } from "../../api/_type";
import Winylo from "../../winylo";
import GoBack from "../../component/GoBack/GoBack";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ModalGalery from "../../component/ModalGalery/ModalGalery";
import { useParams } from "react-router-dom";
import Previewer from "../../component/Previewer/Previewer";
import { MY_DOMAIN, toastify, toFileArray } from "../../utils/utils";
import { ShepherdTourContext } from "react-shepherd";

interface Props {
  idProduct?: string;
  category?: SmallCategory;
  noRedirect?: boolean;
}

export default function CreateProductComponent(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const create = props.idProduct === undefined;

  const { data: product } = useQuery([props.idProduct], () => api.product.getOneProduct(parseInt(props.idProduct!)), {
    onSuccess: (data) => {
      setMedias(data.medias);
    },
    enabled: !create,
  });

  const inputRef = useRef<any>(null);

  const [files, setFiles] = useState<File[]>([]);
  const [captures, setCaptures] = useState<Capture[]>([]);
  const [medias, setMedias] = useState<Media[]>([]);
  const [mediasToDelete, setMediasToDelete] = useState<Media[]>([]);
  const [modalGaleryOpen, setModalGaleryOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tour = useContext(ShepherdTourContext);

  const [successMsg, setSuccessMsg] = useState("");

  const queryClient = useQueryClient();

  const { mutate: createProduct } = useMutation(api.product.addProduct, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (result, variable) => {
      let path = "products";

      if (props.category !== undefined) {
        createSelectedProduct({
          idCategory: props.category.id,
          idProduct: result.product.id,
        });
        path = "categories";

        setSuccessMsg("Produit ajouté !");
        setFiles([]);
        formik.resetForm({
          values: initialValues,
        });
        setFiles([]);
        setCaptures([]);
      }
      let promises = [];
      if (variable.file.length > 0) {
        promises.push(
          api.product.uploadImage({
            productId: result.product.id,
            images: variable.file,
          })
        );
      }
      if (variable.captures.length > 0) {
        promises.push(
          api.product.defineExternalFile({
            captureId: result.product.id,
            body: variable.captures.map((ca) => ({
              mimeType: ca.type,
              fileName: ca.file,
            })),
          })
        );
      }

      Promise.all(promises).then((values) => {
        success(path);
      });
    },
  });

  const { mutate: updateProduct } = useMutation(api.product.updateProduct, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (result, variable) => {
      let promises: any[] = [];

      if (variable.file.length > 0) {
        promises.push(
          api.product.uploadImage({
            productId: variable.productId,
            images: variable.file,
          })
        );
      }
      if (variable.captures.length > 0) {
        promises.push(
          api.product.defineExternalFile({
            captureId: variable.productId,
            body: variable.captures.map((ca) => ({
              mimeType: ca.type,
              fileName: ca.file,
            })),
          })
        );
      }

      Promise.all(promises.concat(mediasToDelete.map((m) => confirmDeleteMedia(m.id)))).then((values) => {
        setMediasToDelete([]);
        success("products");
      });

      // queryClient.setQueryData("categories", (old: any) => old.map((category: Category) => (category.id === result.id ? result : category)));
      // queryClient.setQueryData("filtered_categories", (old: any) => old.map((category: Category) => (category.id === result.id ? result : category)));
    },
  });

  const { mutate: createSelectedProduct } = useMutation(api.selectedProduct.addSelectedProduct, {
    onSuccess: (selectedProduct) => {
      queryClient.invalidateQueries("selectedCategories");
      queryClient.invalidateQueries("catalog_products");
      // queryClient.setQueryData("category", (old: any) => {
      //   if (old) {
      //     return {
      //       ...old,
      //       selectedProducts: [...old.selectedProducts, selectedProduct],
      //     };
      //   }
      // });
    },
  });

  function success(path: string) {
    setIsLoading(false);
    queryClient.invalidateQueries("products");
    queryClient.invalidateQueries("catalog");

    if (props.noRedirect) {
      formik.resetForm({
        values: initialValues,
      });
      setFiles([]);
      setCaptures([]);
      setMedias([]);
    } else {
      navigate(`/${path}`);
    }
  }

  const deleteFile = (file: File) => {
    formik.setFieldValue(
      "file",
      formik.values.file?.filter((f) => f !== file)
    );
    setFiles(files.filter((f) => f !== file));
  };

  const deleteCapture = (capture: Capture) => {
    formik.setFieldValue(
      "captures",
      formik.values.captures?.filter((c) => c.file !== capture.file)
    );
    setCaptures(captures.filter((c) => c.file !== capture.file));
  };

  const deleteMedia = (media: Media) => {
    setMedias(medias.filter((m) => m.id !== media.id));
    setMediasToDelete((old) => [...old, media]);
  };

  const { mutateAsync: confirmDeleteMedia } = useMutation(api.medias.deleteMedia, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (result, idMediaDeleted) => {
      // setMediasToDelete(medias.filter((media) => media.id !== idMediaDeleted));
      setIsLoading(false);
    },
  });

  // const qualityOptions = [
  //   { value: VIDEO_QUALITY.LOW, label: "Basse (480p)" },
  //   { value: VIDEO_QUALITY.MEDIUM, label: "Moyenne (720p)" },
  //   { value: VIDEO_QUALITY.HIGH, label: "Haute (1080p)" },
  // ];

  function onClickChooseFile() {
    if (inputRef.current) inputRef.current.click();
  }

  function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    let isFileTooBig = false;
    const mFiles = toFileArray(e.currentTarget.files).filter((f) => {
      const temp = f.size > 10485760;
      if (temp) {
        isFileTooBig = true;
      }
      return !temp;
    });

    isFileTooBig && toastify("Les fichiers ne peuvent dépasser 10 mo !");

    setFiles((old) => old.concat(mFiles));
    formik.setFieldValue("file", formik.values.file?.concat(mFiles));

    inputRef.current.value = "";

    tour?.isActive() && tour.next();
  }

  function handleChooseFromGalery(newCaptures: Capture[]) {
    setCaptures((old) => old.concat(newCaptures));
    setModalGaleryOpen(false);
    formik.setFieldValue("captures", captures.concat(newCaptures));
  }

  interface InitialValue {
    name: string;
    description: string;
    priceHt?: number;
    priceTtc?: number;
    category?: Category;
    file: File[];
    captures: Capture[];
  }

  const initialValues: InitialValue = {
    name: "",
    description: "",
    priceHt: undefined,
    priceTtc: undefined,
    category: undefined,
    file: [],
    captures: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Le nom du produit ne peut pas être vide."),
      description: Yup.string(),
      priceHt: Yup.number().positive("Le prix doit être supérieur à 0"),
      priceTtc: Yup.number().positive("Le prix doit être supérieur à 0"),
      file: Yup.mixed(),
      captures: Yup.mixed(),
    }),
    onSubmit: (values) => {
      let body = {
        name: values.name,
        description: values.description,
        priceHt: values.priceHt || null,
        priceTtc: values.priceTtc || null,
        file: values.file,
        captures: values.captures,
      };
      if (create) {
        createProduct(body);
      } else {
        if (product?.id !== undefined) updateProduct({ ...body, productId: product.id });
      }
    },
  });

  const { mutate: moveMedia } = useMutation(api.medias.move, {
    onSuccess(data) {
      let mediasCopy = [...medias];

      const mediaA = mediasCopy.find((i) => i.id === data.id);
      const mediaB = mediasCopy.find((i) => i.position === data.position);

      if (!mediaA || !mediaB) return;

      mediaB.position = mediaA.position;
      mediaA.position = data.position;

      mediasCopy.sort((a, b) => (a.position > b.position ? 1 : -1));

      setMedias(mediasCopy);
    },
  });

  function handleChangeMediaPosition(idA: number, idB: number) {
    const mediaA = medias.find((i) => i.id === idA);
    const mediaB = medias.find((i) => i.id === idB);

    if (!mediaA || !mediaB) {
      return;
    }
    moveMedia({ id: idA, position: mediaB.position, initialPosition: mediaA.position });
  }

  useEffect(() => {
    if (product) {
      formik.resetForm({
        values: {
          name: product.name,
          description: product.description,
          priceHt: product.priceHt || undefined,
          priceTtc: product.priceTtc || undefined,
          file: [],
          captures: [],
        },
      });
      setMedias(product.medias);
    } else {
      formik.resetForm({
        values: initialValues,
      });
      setMedias([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <>
      {successMsg && <p style={{ fontWeight: 700 }}>{successMsg}</p>}
      <div style={{ display: "flex" }}>
        <form onSubmit={formik.handleSubmit} style={{ flex: 1 }}>
          <div className="onboarding-createproduct-3rd">
            <Winylo.Input
              id="name"
              name="name"
              variant={formik.touched.name && formik.errors.name ? "invalid" : "normal"}
              message={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
              label="Nom du produit*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              icon={<></>}
            ></Winylo.Input>
            <label className={style.label}>Description</label>

            <ReactQuill
              theme="snow"
              value={formik.values.description}
              onChange={(content) => formik.setFieldValue("description", content)}
              style={{ overflowY: "scroll", resize: "vertical", wordBreak: "break-all", marginBottom: "1rem" }}
            />

            <Winylo.Input
              icon={null}
              type={"number"}
              id="priceHt"
              name="priceHt"
              variant={formik.touched.priceHt && formik.errors.priceHt ? "invalid" : "normal"}
              message={formik.touched.priceHt && formik.errors.priceHt ? formik.errors.priceHt : undefined}
              label="Prix HT"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.priceHt || ""}
            ></Winylo.Input>
            <Winylo.Input
              icon={null}
              type={"number"}
              id="priceTtc"
              name="priceTtc"
              variant={formik.touched.priceTtc && formik.errors.priceTtc ? "invalid" : "normal"}
              message={formik.touched.priceTtc && formik.errors.priceTtc ? formik.errors.priceTtc : undefined}
              label="Prix TTC"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.priceTtc || ""}
            ></Winylo.Input>
          </div>
          <div style={{ textAlign: "center" }}>
            <Winylo.Button
              id="onboarding-createproduct-next-5th"
              className="onboarding-createproduct-5th"
              type="submit"
              style={{ marginTop: "1.875rem" }}
              disabled={isLoading}
            >
              {product === undefined ? "Créer un produit" : "Modifier le produit"}
            </Winylo.Button>
          </div>
        </form>
        <div style={{ flex: 2, marginLeft: "3.5714rem" }}>
          <label className={style.label}>Images / vidéos (max 10mo). Taille de l'image optimale : 428x340</label>
          <input
            style={{ display: "none" }}
            type="file"
            multiple
            ref={inputRef}
            onChange={handleFileSelected}
            accept="image/png, image/jpeg, video/mp4"
          />

          <div style={{ display: "flex", alignItems: "stretch" }}>
            <Winylo.Button
              id="onboarding-createproduct-next-4th"
              className="onboarding-createproduct-4th"
              format="square"
              size="large"
              onClick={onClickChooseFile}
              disabled={isLoading}
            >
              Choisir des fichiers
            </Winylo.Button>
            <Winylo.Button
              style={{
                color: "black",
                display: "flex",
                justifyContent: "center",
                padding: 0,
                paddingLeft: "2rem",
                paddingRight: "2rem",
                marginLeft: "1rem",
              }}
              format="square"
              size="xs"
              variant="white"
              onClick={() => setModalGaleryOpen(true)}
              disabled={isLoading}
            >
              Ouvrir Photos & Vidéos{" "}
              <img
                src={`${MY_DOMAIN}/p&v-logo.png`}
                style={{
                  height: "2.5571rem",
                  width: "2.5571rem",
                }}
              />
            </Winylo.Button>
          </div>

          <Previewer
            files={files}
            captures={formik.values.captures}
            medias={medias}
            deleteFile={deleteFile}
            deleteCapture={deleteCapture}
            deleteMedia={deleteMedia}
            changePosition={handleChangeMediaPosition}
          />
        </div>
      </div>
      <ModalGalery isOpen={modalGaleryOpen} setIsOpen={setModalGaleryOpen} onChooseMultiple={handleChooseFromGalery} />
    </>
  );
}
