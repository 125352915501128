import React, { useState } from "react";
import classNames from "classnames";
import style from "../css/SortMenuRow.module.css";
import { ReactComponent as PlusIcon } from "../svg/plus.svg";
import { ReactComponent as MinusIcon } from "../svg/minus.svg";

interface Props {
  title: string;
  children: React.ReactNode;
  open?: boolean;
}

export default function SortMenuRow({ title, children, open }: Props) {
  const [isOpen, setIsOpen] = useState(open || false);

  return (
    <div className={classNames(style.row, { [style.open]: isOpen })}>
      <div className={style.title} onClick={() => setIsOpen((b) => !b)}>
        <p>{title}</p>
        {isOpen ? (
          <MinusIcon title="Réduire" style={{ width: ".75rem", height: ".75rem" }} />
        ) : (
          <PlusIcon title="Ouvrir" style={{ width: ".75rem", height: ".75rem" }} />
        )}
      </div>
      <div className={style.content}>{children}</div>
    </div>
  );
}
