import Previewer from "../Previewer/Previewer";
import Winylo from "../../winylo";
import ModalGalery from "../ModalGalery/ModalGalery";
import { useRef, useState } from "react";
import { Capture, Media } from "../../api/_type";
import { MY_DOMAIN, toFileArray } from "../../utils/utils";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  files?: File[] | null;
  captures?: Capture[];
  media?: Media[];

  setFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  setCaptures?: React.Dispatch<React.SetStateAction<Capture[]>>;
  setMedias?: React.Dispatch<React.SetStateAction<Media[]>>;

  deleteFile?: (file: File) => void;
  deleteCapture?: (capture: Capture) => void;
  deleteMedia?: (media: Media) => void;

  title?: string;
  multiple?: boolean;
  isLoading: boolean;

  imageInputContainerStyle?: React.CSSProperties;
}

export default function ImageInput(props: Props) {
  const [modalGaleryOpen, setModalGaleryOpen] = useState(false);

  const inputRef = useRef<any>(null);

  function onFileClick() {
    if (inputRef.current) inputRef.current.click();
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const mFile = toFileArray(e.currentTarget.files);
    if (mFile && props.setFiles) props.setFiles((o) => [...o, ...mFile]);

    if (!props.multiple) {
      if (props.captures && props.captures.length > 0) {
        props.captures.forEach((capture) => {
          props.deleteCapture && capture && props.deleteCapture(capture);
        });
      }

      // if (props.medias && props.medias.length > 0) {
      //   props.medias.forEach((media) => {
      //     props.deleteMedia && media && props.deleteMedia(media);
      //   });
      // }
    }
  }

  function handleOneChooseFromGalery(capture: Capture) {
    setModalGaleryOpen(false);

    props.captures && props.captures?.[0] && props.setCaptures && props.setCaptures([props.captures[0]]);

    props.files && props.setFiles && props.setFiles([]);
    // if (props.medias && props.medias.length > 0) {
    //   props.medias.forEach((media) => {
    //     props.deleteMedia && media && props.deleteMedia(media);
    //   });
    // }
  }

  function handleMultipleChooseFromGalery(captures: Capture[]) {
    setModalGaleryOpen(false);

    captures.forEach((capture) => {
      props.setCaptures && capture && props.setCaptures((old) => [...old, capture]);
    });
  }

  return (
    <>
      <div style={{ ...props.imageInputContainerStyle, flex: 2, marginLeft: "1rem", marginBottom: "1rem" }}>
        <label style={{ fontWeight: "600", fontSize: "0.875rem" }}>{props.title || ""}</label>
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.files !== undefined && (
            <>
              <input
                style={{ display: "none" }}
                type="file"
                multiple={props.multiple}
                ref={inputRef}
                onChange={onFileChange}
                accept="image/png, image/jpeg, video/mp4"
              />

              <Winylo.Button format="square" size="large" onClick={onFileClick} disabled={props.isLoading}>
                {props.multiple ? "Choisir des fichiers" : "Choisir un fichier"}
              </Winylo.Button>
            </>
          )}
          {props.captures !== undefined && (
            <>
              <Winylo.Button
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  padding: 0,
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  marginLeft: "1rem",
                }}
                format="square"
                size="xs"
                variant="white"
                onClick={() => setModalGaleryOpen(true)}
                disabled={props.isLoading}
              >
                Ouvrir Photos & Vidéos{" "}
                <img
                  src={`${MY_DOMAIN}/p&v-logo.png`}
                  style={{
                    height: "2.5571rem",
                    width: "2.5571rem",
                  }}
                />
              </Winylo.Button>
            </>
          )}
        </div>
        <Previewer
          files={props.files || []}
          captures={props.captures}
          medias={props.media}
          deleteFile={props.deleteFile}
          deleteCapture={props.deleteCapture}
          deleteMedia={props.deleteMedia}
        />
      </div>
      {props.multiple ? (
        <ModalGalery isOpen={modalGaleryOpen} setIsOpen={setModalGaleryOpen} onChooseMultiple={handleMultipleChooseFromGalery} />
      ) : (
        <ModalGalery isOpen={modalGaleryOpen} setIsOpen={setModalGaleryOpen} onChooseOne={handleOneChooseFromGalery} />
      )}
    </>
  );
}
