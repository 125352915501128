import { host, checkStatus } from "./api";
import { Media } from "./_type";

const medias = {
  deleteMedia: (idMedia: number): Promise<string> => {
    return fetch(`${host}/api/medias/${idMedia}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  move: ({ id, position, initialPosition }: { id: number; position: number; initialPosition: number }): Promise<Media> => {
    return fetch(`${host}/api/medias/${id}/position`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ position }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default medias;
