import { faCamera, faSearch, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useInfiniteQuery } from "react-query";
import api from "../../api/api";
import { Capture, FilterType } from "../../api/_type";
import { MONTHS } from "../../utils/constantes";
import { dateToString, isVowel, parseDate } from "../../utils/utils";
import Winylo from "../../winylo";
import Card from "../../winylo/components/Card";
import Modal from "../../winylo/components/Modal";
import Separator from "../../winylo/components/Separator";

import style from "./ModalGalery.module.css";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChooseOne?: (capture: Capture) => void;
  onChooseMultiple?: (captures: Capture[]) => void;
  maxChooseMedia?: number;
}

export default function ModalGalery({ isOpen, setIsOpen, onChooseOne, onChooseMultiple, maxChooseMedia = 4 }: Props) {
  const today = new Date();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [captures, setCaptures] = useState<Map<string, Capture[]>>(new Map());
  const [capturesTotalLength, setCapturesTotalLength] = useState<number>(0);
  const [selectedCaptures, setSelectedCaptures] = useState<Capture[]>([]);

  // const [dateBegin, setDateBegin] = useState<string>("");
  // const [dateEnd, setDateEnd] = useState<string>("");

  const {
    refetch: refetchCaptures,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery("captures", ({ pageParam = 1 }) => api.captures.getCaptures({ page: pageParam, sort: FilterType.DATE_RECENT }), {
    onSuccess: (data) => {
      const items = data.pages.map((page) => page.items).flat();

      const result: Map<string, Capture[]> = new Map();

      items.map((item) => {
        const itemDate = parseDate(item.creationDate);
        const key: string = `${itemDate.getFullYear().toString()}/${itemDate.getMonth().toString()}`;

        if (!result.has(key)) {
          result.set(key, [item]);
        } else {
          const value = result.get(key) as Capture[];
          result.set(key, [...value, item]);
        }
      });

      setCaptures(result);
      setCapturesTotalLength(data.pages[0].pagination.totalCount);
      setIsLoading(false);
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.pagination.current < lastPage.pagination.endPage) {
        return lastPage.pagination.current + 1;
      } else {
        return undefined;
      }
    },
    keepPreviousData: true,
  });

  function handleClick(c: Capture) {
    if (onChooseOne !== undefined) {
      onChooseOne(c);
    }

    if (onChooseMultiple !== undefined) {
      if (selectedCaptures.find((ca) => ca.uri === c.uri) === undefined) {
        if (selectedCaptures.length < maxChooseMedia) {
          setSelectedCaptures((before) => [...before, c]);
        }
      } else {
        setSelectedCaptures((before) => before.filter((ca) => ca.uri !== c.uri));
      }
    }
  }

  function scrollHandler(e: React.UIEvent<HTMLDivElement, UIEvent>) {
    const scrollHeight = e.currentTarget.scrollHeight || 0;
    const scrollTop = e.currentTarget.scrollTop || 0;
    const clientHeight = e.currentTarget.clientHeight || 0;

    if (scrollHeight - scrollTop <= clientHeight + 300 && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedCaptures([]);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Votre galerie Photos & Vidéos"
      modalStyle={{
        content: { margin: "2rem 5vw 0rem 5vw", width: "100vw", height: "100vh", overflow: "unset", display: "flex", flexDirection: "column" },
      }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      childrenContainerStyle={{ position: "relative", flex: 1 }}
    >
      <Card className={style.modalCard}>
        <div className={style.miscContainer}>
          {/* <div className={style.filtersContainer}>
            <span className={style.filtersLabel}>Filtrer par date</span>
            <div className={style.filtersInput}>
              <Winylo.Input onChange={(e) => setDateBegin(e.target.value)} value={dateBegin} type={"date"} placeholder={"Date du"} icon={<></>} />
            </div>
            <div className={style.filtersInput}>
              <Winylo.Input onChange={(e) => setDateEnd(e.target.value)} value={dateEnd} type={"date"} placeholder={"Au"} icon={<></>} />
            </div>
            <div className={style.filtersSubmit}>
              <Winylo.Button
                style={{ height: "2.3036rem", width: "2.3036rem" }}
                size="xs"
                format="square"
                onClick={() => {
                  "faire fct qui filtre la période (si on l'a entièrement chargée) sinon requête dédiée à p&v (snif pas le choix)";
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Winylo.Button>
            </div>
          </div> */}
          {onChooseMultiple !== undefined ? (
            <Winylo.Button variant="blue" format="square" disabled={selectedCaptures.length === 0} onClick={() => onChooseMultiple(selectedCaptures)}>
              Choisir {selectedCaptures.length} image{selectedCaptures.length > 0 && "s"}
            </Winylo.Button>
          ) : null}
        </div>

        <div className={style.gridContainer} onScroll={(e) => scrollHandler(e)}>
          {isLoading ? (
            <div style={{ padding: "5px" }}>Chargement...</div>
          ) : captures && captures.size > 0 ? (
            Array.from(captures).map(([key, capturesByKey], index) => {
              const date = key.split("/") as [string, number];
              const year = date[0];
              const month = date[1];
              return (
                <>
                  {index > 0 && <Separator className={style.separator} />}
                  <span className={style.capturesDate}>
                    {index === 0
                      ? "Les plus récents"
                      : `Mois ${isVowel(MONTHS.long[month][0]) ? "d'" : "de "}${MONTHS.long[month]} ${
                          year != today.getUTCFullYear().toString() ? year : ""
                        }`}
                  </span>

                  <div className={style.capturesContainer}>
                    {capturesByKey.map((c) => {
                      return (
                        <div className={style.galeryContainer} onClick={() => handleClick(c)}>
                          <img
                            title={c.name}
                            className={`${style.galeryImg} ${selectedCaptures.find((ca) => ca.uri === c.uri) !== undefined ? style.selected : ""}`}
                            alt={c.name}
                            src={c.uri_thumbnail}
                          />
                          <FontAwesomeIcon
                            title={c.type.includes("video") ? "Vidéo" : "Image"}
                            className={style.typeIcon}
                            icon={c.type.includes("video") ? faVideo : faCamera}
                          />
                          {/* idée 1 : mettre icône "Informations" et au passage de la souris affiche (comme les ? de IC) */}
                          {/* idée 2 : mettre un bouton "Reverse" qui retourne l'image et on voit les infos */}
                          {/* <div>{dateToString(c.creationDate)}</div> */}
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })
          ) : (
            <div style={{ padding: "5px" }}>Erreur dans le chargement des captures.</div>
          )}
        </div>
      </Card>
    </Modal>
  );
}
