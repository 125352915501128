import React from "react";
import classNames from "classnames";
import style from "../css/SortMenu.module.css";
import { ReactComponent as EraserIcon } from "../svg/eraser-solid.svg";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  children?: React.ReactNode;
  onClear?: () => void;
}

export default function SortMenu(props: Props) {
  return (
    <div {...props} className={classNames(style.menu, props.className)}>
      <div className={style.titleContainer}>
        <p>{props.title}</p>
        {props.onClear !== undefined && <EraserIcon className={style.eraser} title={"Réinitialiser les filtres"} onClick={props.onClear} />}
      </div>
      {props.children}
    </div>
  );
}
