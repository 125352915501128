import React, { useEffect, useRef, useState } from "react";
import style from "../css/Select.module.css";

import { ReactComponent as CaretDown } from "../svg/caret-down.svg";

import classNames from "classnames";

interface Props {
  children: (React.ReactElement | React.ReactElement[])[];
  value?: string | number;
  onChange?: (newValue: string) => void;
  label?: React.ReactNode;
  invalid?: boolean;
  className?: string;
  background?: "gray" | "white";
}

export default function Select(props: Props) {
  // SOIT si une valeur est passée, on cherche dans le tableau des children lequel a comme valeur la bonne et on prend son contenu
  // SOIT on prend le contenu du premier children
  const [value, setValue] = useState(
    props.children.flat().find((child) => child.props.value === props.value)?.props.children || props.children.flat()[0].props.children
  );

  useEffect(() => {
    setValue(props.children.flat().find((child) => child.props.value === props.value)?.props.children || props.children.flat()[0].props.children);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.children, props.value]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, false);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClickValue(e: any, value: string) {
    e.stopPropagation();

    // On cherche dans les children quel est celui qui a la bonne valeure et on prend son contenu
    setValue(props.children.flat().find((child) => child.props.value === value)?.props.children);
    setIsMenuOpen(false);

    if (props.onChange !== undefined) props.onChange(value);
  }

  const [isMenuOpen, _setIsMenuOpen] = useState(false);
  const menu = useRef<any>(null);
  const menuButton = useRef<any>(null);
  const isMenuOpenRef = useRef<any>(isMenuOpen);
  const setIsMenuOpen = (data: boolean) => {
    isMenuOpenRef.current = data;
    _setIsMenuOpen(data);
  };

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpenRef.current);
  }

  const handleOutsideClick = (e: any) => {
    if (menu.current && !menu.current.contains(e.target) && menuButton.current && !menuButton.current.contains(e.target)) {
      if (isMenuOpenRef.current) {
        toggleMenu();
      }
    }
  };

  return (
    <div>
      {props.label !== undefined ? <p className={style.label}>{props.label}</p> : null}

      <div
        className={classNames(
          style.select,
          {
            [style.invalid]: props.invalid,
            [style.backgroundWhite]: props.background === "white",
          },
          props.className
        )}
        onClick={toggleMenu}
        ref={menuButton}
      >
        <p>{value}</p>
        <CaretDown
          className={classNames(style.caretDown, {
            [style.reverseCaretDown]: isMenuOpen === true,
          })}
        />
        <div
          ref={menu}
          className={classNames(style.dropDown, {
            [style.opened]: isMenuOpen,
            [style.backgroundWhite]: props.background === "white",
          })}
        >
          {props.children.flat().map((child, index) => (
            <p key={`select_${child.props.value}_${index}`} onClick={(e) => handleClickValue(e, child.props.value)}>
              {child.props.children}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
