import { useNavigate, useParams } from "react-router-dom";
import GoBack from "../../component/GoBack/GoBack";
import Winylo from "../../winylo";
import CreateProductComponent from "../CreateProductComponent/CreateProductComponent";

import style from "./CreateProduct.module.css";

interface Props {}

export default function CreateProduct(props: Props) {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <Winylo.Card className={style.card} text={"Produits"}>
        <GoBack text="Retour à la liste des produits" onClick={() => navigate("/products")} />
        <CreateProductComponent idProduct={id} />
      </Winylo.Card>
    </div>
  );
}
