import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils/utils";

export function shareCatalogSteps(history: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Partager un catalogue</span>`;

  const classes: string = "winylo-onboarding";

  const defaultDelay: number = 50;

  const stepsLength = 4;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "share-catalog-1st",
      attachTo: { element: ".onboarding-sharecatalog-1st", on: "right" },
      advanceOn: { selector: ".onboarding-sharecatalog-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history("/catalogs");
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur l\'onglet <span class="important-text">Catalogues</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "share-catalog-2nd",
      attachTo: { element: ".onboarding-sharecatalog-2nd", on: "left" },
      advanceOn: { selector: ".onboarding-sharecatalog-2nd", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-sharecatalog-2nd");
            simulateMouseClick(element);
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
        Cliquez ensuite sur l’icône <span class="important-text-black">Partager</span> <img src="${MY_DOMAIN}/onboarding/share-alt-solid.svg" width="14" height="14"/>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "share-catalog-3rd",
      attachTo: { element: ".onboarding-sharecatalog-3rd", on: "right" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            const element = document.getElementById("onboarding-catalog-share-close");
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            const element = document.getElementById("onboarding-catalog-share-close");
            simulateMouseClick(element);
            this.next();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
        Ici, vous pouvez récupérer le <span class="important-text">QR Code</span> lié à votre <span class="important-text">catalogue</span><br/>
        et le télécharger sous format <span class="important-text">PDF</span>.<br/>
        <br/>
        Vous avez aussi accès le <span class="important-text-yellow">lien de partage</span>, que vous pouvez copier<br/>
        et envoyer aux personnes auxquelles vous souhaitez partager<br/>
        votre <span class="important-text">catalogue</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "share-catalog-4th",
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: completeText,
          action() {
            this.complete();
          },
        },
      ],
      classes,
      highlightClass: "highlight",
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [`Vous avez terminé le tutoriel <span class="important-text">${title}</span> !`],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
