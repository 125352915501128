import { faCheck, faEnvelope, faPen, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { Capture, Catalogue, Media, PRICE_TYPE, Product, PublicCatalogue } from "../../api/_type";
import Winylo from "../../winylo";
import Button from "../../winylo/components/Button";
import GoBack from "../GoBack/GoBack";
import ImageInput from "../ImageInput/ImageInput";
import style from "./ProductComponent.module.css";
interface Props {
  catalog: Catalogue | PublicCatalogue;
  product: Product;
  goBack: () => void;
  showGoBack: boolean;
  setProductToShow: React.Dispatch<React.SetStateAction<Product | undefined>>;
  isEdit: boolean;
  isContactable?: boolean;
  setIsModalContactOpen?: (value: React.SetStateAction<number>) => void;
}

export default function ProductComponent({
  catalog,
  product,
  goBack,
  showGoBack,
  setProductToShow,
  isEdit,
  isContactable,
  setIsModalContactOpen,
}: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>(product.medias.length > 0 ? product.medias[0] : undefined);
  const [mediasToDelete, setMediasToDelete] = useState<Media[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [isNewImage, setIsNewImage] = useState<boolean>(false);

  const [file, setFile] = useState<File[]>([]);
  const [captures, setCapture] = useState<Capture[]>([]);

  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [editTitle, setEditTitle] = useState<boolean>(false);

  const [previewDescription, setPreviewDescription] = useState<string>("");
  const [editDescription, setEditDescription] = useState<boolean>(false);

  const [previewPriceHT, setPreviewPriceHT] = useState<number | null>(null);
  const [editPriceHT, setEditPriceHT] = useState<boolean>(false);

  const [previewPriceTTC, setPreviewPriceTTC] = useState<number | null>(null);
  const [editPriceTTC, setEditPriceTTC] = useState<boolean>(false);

  const { mutate: deleteMedia } = useMutation(api.medias.deleteMedia, {
    onSuccess: (data, idMedia) => {
      // setProductToShow(data);
      const newProduct = {
        id: product.id,
        name: product.name,
        description: product.description,
        priceHt: product.priceHt,
        priceTtc: product.priceTtc,
        medias: product.medias.filter((m) => m.id !== idMedia),
      };
      if (selectedMedia) {
        const index = product.medias.indexOf(selectedMedia);
        index >= 0
          ? newProduct.medias[index]
            ? setSelectedMedia(newProduct.medias[index])
            : index > 0
            ? setSelectedMedia(newProduct.medias[index - 1])
            : setSelectedMedia(newProduct.medias[0])
          : setSelectedMedia(undefined);
      }

      setProductToShow(newProduct);
      queryClient.invalidateQueries();
    },
  });

  const { mutate: updateProduct } = useMutation(api.product.updateProduct, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (result, variable) => {
      let promises: any[] = [];

      if (variable.file !== null && variable.file.length > 0) {
        promises.push(
          api.product
            .uploadImage({
              productId: variable.productId,
              images: variable.file,
            })
            .then((data) => {
              if (variable.captures !== null && variable.captures.length > 0) {
                return uploadExternalFiles(variable);
              } else {
                return data;
              }
            })
        );
      } else if (variable.captures !== null && variable.captures.length > 0) {
        promises.push(uploadExternalFiles(variable));
      } else {
        promises.push(result);
      }

      Promise.all(promises).then((values: Product[]) => {
        const newProduct = values[0];

        let index = newProduct.medias.length - 1;
        if (index < 0) {
          index = 0;
        }

        setSelectedMedia(newProduct.medias[index]);

        resetNewImage();
        setIsLoading(false);
        setProductToShow(newProduct);
        success("product");
      });
    },
  });

  function successNewMedia() {}

  function success(path: string) {
    setIsLoading(false);
    queryClient.refetchQueries("selectedCategories");
    queryClient.invalidateQueries("catalog_products");
  }

  const deleteFile = (file: File) => {
    setFile((old) => old.filter((of) => file !== of));
  };

  const deleteCapture = (capture: Capture) => {
    setCapture([]);
  };

  function uploadExternalFiles(variable: any) {
    return api.product
      .defineExternalFile({
        captureId: variable.productId,
        body: variable.captures.map((ca: any) => ({
          mimeType: ca.type,
          fileName: ca.file,
        })),
      })
      .then((res) => {
        return res;
      });
  }

  function resetNewImage() {
    setIsNewImage(false);
    setFile([]);
    setCapture([]);
  }

  function handleNewImageSubmit() {
    setIsLoading(true);
    const { id, name, description, priceHt, priceTtc } = product;

    updateProduct({ productId: id, name, description, priceHt, priceTtc, file, captures });
  }

  function handleDeleteMedia(e: React.MouseEvent<SVGSVGElement, MouseEvent>, media: Media) {
    e.stopPropagation();
    deleteMedia(media.id);
  }

  function handleEditTitle(e: React.MouseEvent<HTMLDivElement, MouseEvent>, trueEdit?: boolean) {
    e.stopPropagation();

    if (trueEdit !== undefined) {
      if (trueEdit) {
        const { id, description, priceHt, priceTtc } = product;

        updateProduct({ productId: id, name: previewTitle, description, priceHt, priceTtc, file: [], captures: [] });
      } else setPreviewTitle(product.name);
    }
    setEditTitle(!editTitle);
  }

  function handleTitleChange(content: string) {
    setPreviewTitle(content);
  }

  function handleEditDescription(e: React.MouseEvent<HTMLDivElement, MouseEvent>, trueEdit?: boolean) {
    e.stopPropagation();

    if (trueEdit !== undefined) {
      if (trueEdit) {
        const { id, name, priceHt, priceTtc } = product;

        updateProduct({ productId: id, name, description: previewDescription, priceHt, priceTtc, file: [], captures: [] });
      } else setPreviewDescription(product.description);
    }
    setEditDescription(!editDescription);
  }

  function handleDescriptionChange(content: string) {
    setPreviewDescription(content);
  }

  function handleEditPriceHT(e: React.MouseEvent<HTMLDivElement, MouseEvent>, trueEdit?: boolean) {
    e.stopPropagation();

    if (trueEdit !== undefined) {
      if (trueEdit) {
        const { id, name, description, priceTtc } = product;

        updateProduct({ productId: id, name, description, priceHt: previewPriceHT, priceTtc, file: [], captures: [] });
      } else setPreviewPriceHT(product.priceHt);
    }
    setEditPriceHT(!editPriceHT);
  }

  function handlePriceHTChange(content: number) {
    setPreviewPriceHT(content);
  }

  function handleEditPriceTTC(e: React.MouseEvent<HTMLDivElement, MouseEvent>, trueEdit?: boolean) {
    e.stopPropagation();

    if (trueEdit !== undefined) {
      if (trueEdit) {
        const { id, name, description, priceHt } = product;

        updateProduct({ productId: id, name, description, priceHt, priceTtc: previewPriceTTC, file: [], captures: [] });
      } else setPreviewPriceTTC(product.priceTtc);
    }
    setEditPriceTTC(!editPriceTTC);
  }

  function handlePriceTTCChange(content: number) {
    setPreviewPriceTTC(content);
  }

  function isInt(n: number) {
    return n % 1 === 0;
  }

  useEffect(() => {
    setPreviewTitle(product.name);
    setPreviewDescription(product.description);
    setPreviewPriceHT(product.priceHt);
    setPreviewPriceTTC(product.priceTtc);
  }, [product]);

  const [zoom, setZoom] = useState<boolean>(false);

  if (!product) return <></>;
  return (
    <>
      {showGoBack && (
        <GoBack style={{ color: `#${catalog.color || "323232"}` }} text="Retour à la liste des produits" onClick={goBack} className={style.goBack} />
      )}
      <div
        className={classNames([style.name, isEdit && !editTitle && style.editButton])}
        onClick={(e) => !editTitle && handleEditTitle(e, undefined)}
      >
        {isEdit ? (
          editTitle ? (
            <div style={{ display: "flex", height: "1.8286rem" }}>
              <Winylo.Input icon={null} type={"text"} value={previewTitle} onChange={(e) => handleTitleChange(e.target.value)} />
              <div className={classNames([style.editButton])} onClick={(e) => handleEditTitle(e, true)}>
                <FontAwesomeIcon title="Valider" style={{ marginLeft: "0.5rem" }} icon={faCheck}></FontAwesomeIcon>
              </div>
              <div className={classNames([style.cancelButton])} onClick={(e) => handleEditTitle(e, false)}>
                <FontAwesomeIcon title="Annuler" style={{ marginLeft: "0.5rem" }} icon={faTimes}></FontAwesomeIcon>
              </div>
            </div>
          ) : (
            <>
              {product.name}
              <FontAwesomeIcon title="Modifier le nom du produit" style={{ marginLeft: "0.5rem" }} icon={faPen}></FontAwesomeIcon>
            </>
          )
        ) : (
          <>{product.name}</>
        )}

        {isContactable && product.id && (
          <>
            <Button
              title="Prendre contact"
              className={classNames(style.productIcon, style.contact)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsModalContactOpen && setIsModalContactOpen(product.id);
              }}
              format={"square"}
            >
              <span style={{ marginRight: "1rem" }}>Contactez-nous</span> <FontAwesomeIcon icon={faEnvelope} />
            </Button>
          </>
        )}
      </div>
      <div className={style.twoColumns}>
        <div className={classNames(style.firstColumn, zoom && style.zoom)}>
          <div
            className={classNames(style.mainImage, zoom && style.zoom, !selectedMedia && style.empty)}
            onClick={() => {
              setZoom(!zoom);
            }}
          >
            {selectedMedia ? (
              selectedMedia.mimeType.includes("video") ? (
                <video playsInline autoPlay muted loop controls>
                  <source src={selectedMedia.uri} />
                </video>
              ) : (
                <img alt={"Produit"} src={selectedMedia.uri} />
              )
            ) : (
              <img alt="Produit" src={process.env.PUBLIC_URL + "/no_media.jpg"} />
            )}
          </div>
          <div className={style.imagesContainer}>
            {product.medias.map((media, index) => (
              <div
                key={`productcomponent_m_${index}`}
                style={{
                  border: media && selectedMedia?.id === media.id ? `2px solid ${catalog.color ? `#${catalog.color}` : "var(--blue)"} ` : "unset",
                }}
                className={classNames(style.smallImage /*, selectedMedia?.id === media.id && style.selected*/)}
                onClick={() => setSelectedMedia(media)}
              >
                {isEdit && (
                  <FontAwesomeIcon title="Supprimer" icon={faTrash} className={style.trashDeleteMedia} onClick={(e) => handleDeleteMedia(e, media)} />
                )}
                {media.mimeType.includes("video") ? (
                  <video playsInline muted loop>
                    <source src={media.uri} />
                  </video>
                ) : (
                  <img alt={"Produit"} src={media.uri} />
                )}
              </div>
            ))}
            {isEdit && (
              <div className={style.smallImage} onClick={() => setIsNewImage(true)}>
                <div className={style.editButton} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <FontAwesomeIcon className={style.newMedia} icon={faPlus} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ position: "relative", flex: "1", marginRight: "2rem" }}>
          {catalog?.priceType !== PRICE_TYPE.NONE && (
            <div style={{ display: "flex", height: "1.8286rem", marginBottom: "1.5rem" }}>
              {(catalog?.priceType === PRICE_TYPE.TTC || catalog?.priceType === PRICE_TYPE.BOTH) && (
                <>
                  <div
                    className={classNames([style.price, isEdit && !editPriceTTC && style.editButton])}
                    onClick={(e) => !editPriceTTC && handleEditPriceTTC(e, undefined)}
                  >
                    {isEdit ? (
                      editPriceTTC ? (
                        <div style={{ display: "flex" }}>
                          <Winylo.Input
                            icon={null}
                            type={"number"}
                            value={previewPriceTTC || 0}
                            onChange={(e) => handlePriceTTCChange(+e.target.value)}
                          />
                          <div className={classNames([style.editButton])} onClick={(e) => handleEditPriceTTC(e, true)}>
                            <FontAwesomeIcon title="Valider" style={{ marginLeft: "0.5rem" }} icon={faCheck}></FontAwesomeIcon>
                          </div>
                          <div className={classNames([style.cancelButton])} onClick={(e) => handleEditPriceTTC(e, false)}>
                            <FontAwesomeIcon title="Annuler" style={{ marginLeft: "0.5rem" }} icon={faTimes}></FontAwesomeIcon>
                          </div>
                        </div>
                      ) : (
                        <>
                          {product.priceTtc &&
                            (isInt(product.priceTtc)
                              ? product.priceTtc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € TTC"
                              : product.priceTtc
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € TTC")}
                          <FontAwesomeIcon title="Modifier le prix TTC" style={{ marginLeft: "0.5rem" }} icon={faPen}></FontAwesomeIcon>
                        </>
                      )
                    ) : (
                      <>
                        {product.priceTtc &&
                          (isInt(product.priceTtc)
                            ? product.priceTtc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € TTC"
                            : product.priceTtc
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € TTC")}
                      </>
                    )}
                  </div>
                </>
              )}
              {(catalog?.priceType === PRICE_TYPE.HT || catalog?.priceType === PRICE_TYPE.BOTH) && (
                <>
                  <div
                    className={classNames([style.price, isEdit && !editPriceHT && style.editButton])}
                    onClick={(e) => !editPriceHT && handleEditPriceHT(e, undefined)}
                  >
                    {isEdit ? (
                      editPriceHT ? (
                        <div style={{ display: "flex", height: "1.8286rem" }}>
                          <Winylo.Input
                            icon={null}
                            type={"number"}
                            value={previewPriceHT || 0}
                            onChange={(e) => handlePriceHTChange(+e.target.value)}
                          />
                          <div className={classNames([style.editButton])} onClick={(e) => handleEditPriceHT(e, true)}>
                            <FontAwesomeIcon title="Valider" style={{ marginLeft: "0.5rem" }} icon={faCheck}></FontAwesomeIcon>
                          </div>
                          <div className={classNames([style.cancelButton])} onClick={(e) => handleEditPriceHT(e, false)}>
                            <FontAwesomeIcon title="Annuler" style={{ marginLeft: "0.5rem" }} icon={faTimes}></FontAwesomeIcon>
                          </div>
                        </div>
                      ) : (
                        <>
                          {product.priceHt &&
                            (isInt(product.priceHt)
                              ? product.priceHt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT"
                              : product.priceHt
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT")}
                          <FontAwesomeIcon title="Modifier le prix HT" style={{ marginLeft: "0.5rem" }} icon={faPen}></FontAwesomeIcon>
                        </>
                      )
                    ) : (
                      <>
                        {product.priceHt &&
                          (isInt(product.priceHt)
                            ? product.priceHt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT"
                            : product.priceHt
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT")}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
          {isEdit && (
            <div style={{ fontWeight: 600, marginBottom: "0.25rem", display: "flex" }}>
              {editDescription ? (
                <div style={{ display: "flex" }}>
                  <div
                    title="Valider"
                    className={classNames([style.description, style.editDescriptionButton, style.editButton])}
                    onClick={(e) => handleEditDescription(e, true)}
                  >
                    <span>Valider</span> <FontAwesomeIcon style={{ marginLeft: "0.5rem" }} icon={faCheck}></FontAwesomeIcon>
                  </div>
                  <div
                    title="Annuler"
                    className={classNames([style.description, style.editDescriptionButton, style.editButton], style.cancelButton)}
                    onClick={(e) => handleEditDescription(e, false)}
                  >
                    <span style={{ width: "4.5rem" }}>Annuler</span>{" "}
                    <FontAwesomeIcon style={{ marginLeft: "0.5rem" }} icon={faTimes}></FontAwesomeIcon>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={classNames([style.description, style.editDescriptionButton, style.editButton])}
                    onClick={(e) => handleEditDescription(e, undefined)}
                  >
                    Modifier la description{" "}
                    <FontAwesomeIcon title="Modifier la description" style={{ marginLeft: "0.5rem" }} icon={faPen}></FontAwesomeIcon>
                  </div>
                </>
              )}
            </div>
          )}
          {editDescription ? (
            <ReactQuill
              className={style.description}
              theme="snow"
              value={previewDescription}
              onChange={(content) => handleDescriptionChange(content)}
              style={{ overflowY: "scroll", resize: "vertical" }}
            />
          ) : (
            <div className={style.description} dangerouslySetInnerHTML={{ __html: product.description }} />
          )}
        </div>
      </div>

      <Winylo.Modal
        isOpen={isNewImage}
        onClose={() => resetNewImage()}
        modalStyle={{ content: { width: "100%", maxWidth: "45rem", height: "52%", maxHeight: "80%" } }}
        titlePosition={"left"}
        title="Ajout de médias"
        childrenContainerStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          position: "absolute",
          top: "4rem",
          left: "1rem",
          right: "1rem",
          paddingBottom: "1rem",
          flex: 1,
        }}
      >
        <ImageInput
          files={file}
          captures={captures}
          setFiles={setFile}
          setCaptures={setCapture}
          deleteFile={deleteFile}
          deleteCapture={deleteCapture}
          title={"Images / vidéos (max 10mo)"}
          isLoading={isLoading}
          imageInputContainerStyle={{ alignSelf: "stretch" }}
          multiple
        ></ImageInput>
        {((file && file.length > 0) || captures.length > 0) && (
          <Winylo.Button className={style.newImageButton} disabled={isLoading} size="large" onClick={() => handleNewImageSubmit()}>
            Ajouter les médias
          </Winylo.Button>
        )}
      </Winylo.Modal>
    </>
  );
}
