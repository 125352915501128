import { faPen, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ShepherdTourContext } from "react-shepherd";
import { Catalogue, SelectedCategory, SmallCategory } from "../../api/_type";
import api from "../../api/api";
import Table from "../../lib/Table/Table";
import CreateProductComponent from "../../pages/CreateProductComponent/CreateProductComponent";
import SubCategoriesList from "../../pages/SubCategoriesList/SubCategoriesList";
import icons from "../../utils/icons";
import { MY_DOMAIN, removeHtmlTags } from "../../utils/utils";
import Winylo from "../../winylo";
import Carousel from "../Carousel/Carousel";
import CategorySelectProduct from "../CategorySelectProduct/CategorySelectProduct";
import CreateCategoryComponent from "../CreateCategoryComponent/CreateCategoryComponent";
import carouselStyle from "./Carousel.module.css";
import style from "./CatalogSelectCategory.module.css";

interface CheckboxCategoryProps {
  catalogue: Catalogue;
  category: SmallCategory;
  selectedCategory?: SelectedCategory;
  reloadSelected: (sc: number) => void;
}

function CheckboxCategory(props: CheckboxCategoryProps) {
  const queryClient = useQueryClient();

  const { mutate: createSelectedCategory } = useMutation(api.selectedCategory.addSelectedCategory, {
    onSuccess: (selectedCategory) => {
      props.reloadSelected(props.category.id);

      queryClient.setQueryData<SelectedCategory[]>("selectedCategories", (old: SelectedCategory[] | undefined) => {
        if (old === undefined) return [];
        return [...old, selectedCategory];
      });

      queryClient.invalidateQueries("catalog_products");
    },
  });

  const { mutate: deleteSelectedCategory } = useMutation(api.selectedCategory.deleteSelectedCategory, {
    onSuccess: (data, variables) => {
      props.reloadSelected(props.category.id);

      queryClient.invalidateQueries("selectedCategories");
      queryClient.invalidateQueries("catalog_products");
    },
  });

  function checkboxChange() {
    if (props.selectedCategory === undefined) {
      createSelectedCategory({
        idCatalogue: props.catalogue.id,
        idCategory: props.category.id,
      });
    } else {
      deleteSelectedCategory({ selectedCategoryId: props.selectedCategory.id, linkedCategoryId: props.selectedCategory.category.id });
    }
  }

  return <Winylo.Checkbox checked={props.selectedCategory !== undefined} onChange={checkboxChange} />;
}

interface Props {
  className?: string;
  catalogue: Catalogue;
  selectedCategories: SelectedCategory[];
  reloadSelected?: { selectedCategory?: SmallCategory; setSelectedCategory?: Dispatch<SetStateAction<SmallCategory | undefined>> };
  invalidateQueries?: () => void;
  setSelectedTab?: (index: number) => void;
}

export default function CatalogSelectCategory(props: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const tour = useContext(ShepherdTourContext);

  const categoriesRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState<string>("");

  const [categories, setCategories] = useState<SmallCategory[]>([]);
  const [categoriesTotalLength, setCategoriesTotalLength] = useState<number>(0);

  const {
    refetch: refetchCategories,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery("categories", ({ pageParam = 1 }) => api.categories.getCategories({ page: pageParam }), {
    onSuccess: (data) => {
      let temp = data.pages.map((page) => page.items).flat();

      temp.sort((a, b) => {
        const sc = props.selectedCategories.find((selectedCategory) => selectedCategory.category.id === a.id);

        return sc ? -1 : 1;
      });

      setCategories(temp);
      setCategoriesTotalLength(data.pages[0].pagination.totalCount);
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.pagination.current < lastPage.pagination.endPage) {
        return lastPage.pagination.current + 1;
      } else {
        return undefined;
      }
    },
    keepPreviousData: true,
  });

  const [isSubCategoriesOpen, setIsSubCategoriesOpen] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<SmallCategory | undefined>(undefined);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  function changeSubCategoriesVisibility() {
    setIsSubCategoriesOpen((b) => !b);
  }

  function changeFormVisibility() {
    setIsFormOpen((b) => !b);
  }

  function changeEditVisibility() {
    setIsEditOpen((b) => !b);
  }

  function getCategories() {
    return categories?.filter((category) => category.name.toLowerCase().includes(search.toLowerCase()));
  }

  function confirmDeleteCategory() {
    if (categoryToDelete) {
      deleteCategory(categoryToDelete.id);
      setCategoryToDelete(undefined);
    }
  }

  /**
   * @todo Route pas encore faite
   */
  // const { mutate: CloneCategory } = useMutation(api.categories.cloneCategory, {
  //   onSuccess: (result, variable) => {
  //   },
  // });

  const [categoryToDelete, setCategoryToDelete] = useState<SmallCategory | undefined>(undefined);

  const { mutate: deleteCategory } = useMutation(api.categories.deleteCategory, {
    onSuccess: () => {
      categoryToDelete && reloadSelected(categoryToDelete.id);

      queryClient.invalidateQueries("selectedCategories");
      queryClient.invalidateQueries("catalog_products");
      queryClient.invalidateQueries("categories");
    },
  });

  function reloadSelected(sc: number) {
    if (props.reloadSelected && props.reloadSelected.setSelectedCategory) {
      props.reloadSelected.setSelectedCategory(undefined);
    }
  }

  function renderSubCategories(subCategories: SmallCategory[]) {
    let res: string = "";
    for (let i = 0; i < subCategories.length; i++) {
      res += subCategories[i].name;
      i + 1 < subCategories.length && (res += ", ");
    }

    return res;
  }

  function renderCategory(category: SmallCategory) {
    return (
      <>
        <td>
          <CheckboxCategory
            catalogue={props.catalogue!}
            category={category}
            selectedCategory={props.selectedCategories.find((selectedCategory) => selectedCategory.category.id === category.id)}
            reloadSelected={reloadSelected}
          />
        </td>
        <td className={style.categoryFirstCol}>
          <Carousel
            medias={category.media ? Array(category.media) : []}
            style={carouselStyle}
            unselectedColor="#bdbdbd"
            selectedColor="#19323c"
            isMini={true}
            canZoom
          />
          <div className={style.categoryName}>{category.name}</div>
        </td>
        <td>{renderSubCategories(category.subCategories)}</td>
        <td>
          <span className={style.categoryDescription}>{removeHtmlTags(category.description)}</span>
        </td>
        <td>
          <div className={style.iconsColumn}>
            <div
              className={style.actionIconContainer}
              title="Sous-catégories"
              onClick={() => {
                if (tour?.isActive()) return;
                changeSubCategoriesVisibility();
                setSelectedCategory(category);
              }}
            >
              <FontAwesomeIcon className={style.actionIcon} icon={icons.v6_faArrowRightToBracket} />
            </div>
            <div
              className={style.actionIconContainer}
              title="Gérer des produits"
              onClick={() => {
                if (tour?.isActive()) return;
                changeFormVisibility();
                setSelectedCategory(category);
              }}
            >
              <FontAwesomeIcon className={style.actionIcon} icon={faPlusCircle} />
            </div>

            <div
              className={style.actionIconContainer}
              title="Modifier"
              onClick={() => {
                if (tour?.isActive()) return;
                changeEditVisibility();
                setSelectedCategory(category);
              }}
            >
              <FontAwesomeIcon className={style.actionIcon} icon={faPen} />
            </div>

            <div
              className={style.actionIconContainer}
              title="Supprimer"
              onClick={() => {
                if (tour?.isActive()) return;
                setCategoryToDelete(category);
              }}
            >
              <FontAwesomeIcon className={style.actionIcon} icon={faTrash} />
            </div>
          </div>
        </td>
      </>
    );
  }

  useEffect(() => {
    categoriesRef.current?.addEventListener("scroll", eventHandler);

    function eventHandler() {
      const scrollHeight = categoriesRef.current?.scrollHeight || 0;
      const scrollTop = categoriesRef.current?.scrollTop || 0;
      const clientHeight = categoriesRef.current?.clientHeight || 0;

      if (scrollHeight - scrollTop <= clientHeight + 300 && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    }

    return () => {
      categoriesRef.current?.removeEventListener("scroll", eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <>
      <div className={style.cardHeader}>
        <Winylo.Input
          placeholder="Recherche"
          inputContainerProps={{
            className: style.cardHeaderInput,
          }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
        <Winylo.ImportantNumber
          style={{ marginTop: 0, marginLeft: "1.875rem" }}
          number={categoriesTotalLength}
          text={"Catégorie"}
          textPlural={"Catégories"}
        />
      </div>
      {categories && categories.filter((c) => c.parent === null)?.length > 0 ? (
        <div ref={categoriesRef} className={style.productsContainer} style={{ marginLeft: "1.875rem", textAlign: "left" }}>
          <Table className={style.table}>
            <thead>
              <tr>
                <th style={{ width: "5%" }}>Cocher</th>
                <th style={{ width: "25%" }}>Catégories</th>
                <th style={{ width: "30%" }}>Sous-catégories</th>
                <th style={{ width: "30%" }}>Description</th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody className={props.className}>
              {getCategories()?.map(
                (category) =>
                  !category.parent && (
                    <tr key={category.id} className={style.separator}>
                      {renderCategory(category)}
                    </tr>
                  )
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className={style.categoriesContainerEmpty} onClick={() => props.setSelectedTab && props.setSelectedTab(1)}>
          <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
          <span style={{ marginTop: "1rem" }}>Vous n'avez aucune catégorie pour le moment.</span>
          <span>Cliquez ici pour en créer une !</span>
        </div>
      )}

      <Winylo.Modal title="Supprimer la catégorie" isOpen={!!categoryToDelete} onClose={() => setCategoryToDelete(undefined)}>
        <p>
          Voulez-vous vraiment supprimer la catégorie <span style={{ fontWeight: 600 }}>{categoryToDelete?.name}</span> ?
        </p>
        <div className={style.modalDeleteButtons}>
          <Winylo.Button variant="gray" fullWidth onClick={() => setCategoryToDelete(undefined)}>
            Annuler
          </Winylo.Button>
          <Winylo.Button variant="red" fullWidth onClick={confirmDeleteCategory}>
            Supprimer
          </Winylo.Button>
        </div>
      </Winylo.Modal>

      <Winylo.Modal
        isOpen={isSubCategoriesOpen}
        onClose={changeSubCategoriesVisibility}
        modalStyle={{ content: { width: "100%", maxWidth: "93.75rem", height: "80%" } }}
        titlePosition={"left"}
        title={"Sous-catégories de " + selectedCategory?.name}
      >
        <Winylo.Tabs
          selectedItem={selectedTab}
          updateSelectedItem={(index) => setSelectedTab(index)}
          items={[
            {
              title: "Liste des sous-catégories",
              renderFunction: (category: any) => <SubCategoriesList id={category.id} setSelectedTab={setSelectedTab} catalogue={props.catalogue} />,
              key: "selectedCategory",
            },
            {
              title: "Ajout d'une sous-catégorie",
              renderFunction: (category: any) => <CreateCategoryComponent idParent={category.id} noRedirect />,
              key: "selectedCategory",
            },
          ]}
          itemsDependencies={{
            selectedCategory: [selectedCategory],
            newProduct: [],
          }}
        />
      </Winylo.Modal>

      <Winylo.Modal
        isOpen={isFormOpen}
        onClose={changeFormVisibility}
        modalStyle={{ content: { width: "100%", maxWidth: "93.75rem", height: "80%" } }}
        titlePosition={"left"}
        title="Ajout produits"
      >
        <Winylo.Tabs
          selectedItem={selectedTab}
          updateSelectedItem={(index) => setSelectedTab(index)}
          items={[
            {
              title: "Séléctionner les produits",
              renderFunction: (category: any) => <CategorySelectProduct category={category} setSelectedTab={setSelectedTab} />,
              key: "selectedCategory",
            },
            {
              title: "Ajout nouveau produit",
              renderFunction: (category: any) => <CreateProductComponent category={category} />,
              key: "selectedCategory",
            },
          ]}
          itemsDependencies={{
            selectedCategory: [selectedCategory],
            newProduct: [],
          }}
        />
      </Winylo.Modal>

      <Winylo.Modal
        isOpen={isEditOpen}
        onClose={changeEditVisibility}
        modalStyle={{ content: { width: "100%", maxWidth: "93.75rem", height: "80%" } }}
        titlePosition={"left"}
        title="Edit catégorie"
      >
        {selectedCategory && (
          <CreateCategoryComponent catalogue={props.catalogue} onSubmit={() => changeEditVisibility()} idCategory={selectedCategory.id.toString()} />
        )}
      </Winylo.Modal>
    </>
  );
}
