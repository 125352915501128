import { host, checkStatus, checkStatusWithoutError } from "./api";
import { Capture, Category, PageItem, SelectedCategory, SelectedProduct, SmallCategory, SubCategoryToCreate } from "./_type";
import qs from "qs";

const categories = {
  getCategories: ({ page }: { page?: number }): Promise<PageItem<SmallCategory>> => {
    return fetch(`${host}/api/categories?${qs.stringify({ page })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  addCategory: ({
    name,
    description,
    file,
    capture,
    parent,
  }: {
    name: string;
    description: string;
    file?: File;
    capture: Capture | null;
    parent?: number;
  }): Promise<Category> => {
    return fetch(`${host}/api/categories`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ name, description, parent }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  addSubCategories: ({ subcategories, parent }: { subcategories: SubCategoryToCreate[]; parent: number }): Promise<Category[]> => {
    if (subcategories.length > 0) {
      return fetch(`${host}/api/categories/subs`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
          "content-type": "application/json",
        },
        body: JSON.stringify({ subcategories, parent }),
      })
        .then(checkStatus)
        .then((res) => res?.json())
        .then((res) => {
          return res;
        });
    } else return Promise.resolve([]);
  },
  deleteCategory: (id: number): Promise<string | undefined> => {
    return fetch(`${host}/api/categories/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
  deleteCategories: (ids: number[]): Promise<string | undefined> => {
    if (ids.length > 0) {
      let params = qs.stringify({ ids });
      return fetch(`${host}/api/categories/subs?${params}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        },
      })
        .then(checkStatus)
        .then((res) => res?.text());
    } else return Promise.resolve(undefined);
  },
  updateCategory: ({
    idCategory,
    name,
    description,
    file,
    capture,
  }: {
    idCategory: number;
    name: string;
    description: string;
    file?: File;
    capture: Capture | null;
  }): Promise<Category> => {
    return fetch(`${host}/api/categories/${idCategory}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ name, description }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  updateSubCategories: ({ subcategories, parent }: { subcategories: SubCategoryToCreate[]; parent: number }): Promise<Category | undefined> => {
    if (subcategories.length > 0) {
      return fetch(`${host}/api/categories/subs`, {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
          "content-type": "application/json",
        },
        body: JSON.stringify({ subcategories, parent }),
      })
        .then(checkStatus)
        .then((res) => res?.json());
    } else return Promise.resolve(undefined);
  },
  moveCategory: ({ id, position }: { id: number; position: number }): Promise<Category> => {
    return fetch(`${host}/api/categories/${id}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ position }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getOneCategory: (id: number): Promise<Category> => {
    return fetch(`${host}/api/categories/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  updateImage: ({ categoryId, image }: { categoryId: number; image: File }): Promise<Category> => {
    let data = new FormData();
    data.append("file", image);

    return fetch(`${host}/api/categories/${categoryId}/file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  defineExternalFile: ({ idCagegorie, fileName, mimeType }: { idCagegorie: number; fileName: string; mimeType: string }): Promise<Category> => {
    return fetch(`${host}/api/categories/${idCagegorie}/external-file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ fileName, mimeType }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateSubCategoryPosition: ({
    subCategory,
    newPosition: position,
    parentId,
  }: {
    subCategory: SmallCategory;
    newPosition: number;
    parentId?: number;
  }): Promise<Category> => {
    return fetch(`${host}/api/categories/subs/${subCategory.id}/position`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        position,
      }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getSelectedProductsByCategories: (catalogId: number): Promise<SelectedProduct[]> => {
    return fetch(`${host}/api/categories/${catalogId}/selectedproducts`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getSelectedProductsByPublicCategories: (token: string): Promise<SelectedProduct[]> => {
    return fetch(`${host}/api/catalogues/${token}/selectedproducts`)
      .then(checkStatusWithoutError)
      .then((res) => res.json());
  },
};

export default categories;
