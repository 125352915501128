import React from "react";
import classNames from "classnames";

import defaultStyle from "../css/Card.module.css";
interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text?: string;
  leftElements?: React.ReactNode;
  customCssModule?: any;
}

export default function Card({ text, leftElements, customCssModule, ...other }: Props) {
  let style = defaultStyle;

  if (customCssModule !== undefined) {
    style = customCssModule;
  }

  return (
    <div {...other} className={classNames(style.card, other.className)}>
      {(text || leftElements) && (
        <>
          <div className={style.titleContainer}>
            <span className={style.title}>{text}</span>
            {leftElements && <span>{leftElements}</span>}
          </div>
          <div className={style.separator}></div>
        </>
      )}
      <div>{other.children}</div>
    </div>
  );
}
