import style from "./CreateCategory.module.css";
import Winylo from "../../winylo";
import GoBack from "../../component/GoBack/GoBack";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import CreateCategoryComponent from "../../component/CreateCategoryComponent/CreateCategoryComponent";
import { parseQuery, useQueryParams } from "../../utils/utils";

interface Props {}

export default function CreateCategory(props: Props) {
  const { id } = useParams<{ id: string }>();
  // const { parent } = useParams<{ parent: string }>();

  const parent = useQueryParams().get("parent") || undefined;

  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <Winylo.Card className={style.card} text={`${parent ? "Sous-" : ""}Catégories`}>
        <GoBack
          text={`Retour à la liste des ${parent ? "sous-" : ""}catégories`}
          onClick={() => (parent ? navigate(`/categories/${parent}/subcategories`) : navigate("/categories"))}
        />
        <CreateCategoryComponent idCategory={id} idParent={parent} />
      </Winylo.Card>
    </div>
  );
}
