export enum ROLES {
  ROLE_COMPANY_ADMIN = "ROLE_COMPANY_ADMIN",
  ROLE_APPLICATION_ADMIN = "ROLE_APPLICATION_ADMIN",
  ROLE_APPLICATION_ACCESS = "ROLE_APPLICATION_ACCESS",
}

export interface ErrorApi {
  code: number;
  message: string;
}

export enum PRICE_TYPE {
  NONE = "none",
  HT = "ht",
  TTC = "ttc",
  BOTH = "both",
}

export const PriceType: { [key: string]: string } = {
  [PRICE_TYPE.NONE]: "Aucun",
  [PRICE_TYPE.HT]: "HT",
  [PRICE_TYPE.TTC]: "TTC",
  [PRICE_TYPE.BOTH]: "HT et TTC",
};

export interface SmallCatalogue {
  company: Company;
  id: number;
  name: string;
  description: string;
  media: null | Media;
  banner: null | Media;
  publicToken: string;
  priceType: PRICE_TYPE;
  createdBy: SmallUser;
  createdAt: string;
  views: number;
}

export interface Catalogue extends SmallCatalogue {
  categories: Category[];
  selectedCategories: SelectedCategory[];
  color: string | null;
  showPhone: boolean | null;
  showMail: boolean | null;
  showFacebook: boolean | null;
  showTwitter: boolean | null;
  showLinkedin: boolean | null;
  showInstagram: boolean | null;
  showWebsite: boolean | null;
  showCompanyInfos: boolean | null;
  showQRcode: boolean | null;
  phone: string | null;
  mail: string | null;
  facebook: string | null;
  twitter: string | null;
  linkedin: string | null;
  instagram: string | null;
  website: string | null;
  companyInfos: string | null;
  isContactable: boolean | null;
  isActive: boolean | null;
}

export interface User extends SmallUser {
  email: string;
  createdAt: string;
  company: Company;
  roles: ROLES[];
}

export interface SmallUser {
  id: number;
  firstname: string;
  lastname: string;
}

export interface Company {
  id: number;
  name: string;
}
export interface Category extends SmallCategory {
  selectedProducts: SelectedProduct[];
}

export interface SmallCategory {
  id: number;
  name: string;
  description: string;
  media: null | Media;
  position: number;
  parent: Category | null;
  subCategories: SmallCategory[];
  selectedProductsLength: number;
}

export interface SubCategoryToCreate {
  name: string;
}

export interface SelectedProduct {
  id: number;
  product: Product;
  category: { id: number };
  position: number;
}

export interface SelectedCategory {
  id: number;
  category: Category;
  position: number;
}

export interface Product {
  id: number;
  name: string;
  description: string;
  priceHt: number | null;
  priceTtc: number | null;
  medias: Media[];
}

export interface Media {
  id: number;
  uri: string;
  uri_thumbnail?: string;
  position: number;
  mimeType: string;
}

// Partie publique
export interface PublicCatalogue extends SmallCatalogue {
  categories: PublicCategory[];
  selectedCategories: SelectedCategory[];
  color: string | null;
  showPhone: boolean | null;
  showMail: boolean | null;
  showFacebook: boolean | null;
  showTwitter: boolean | null;
  showLinkedin: boolean | null;
  showInstagram: boolean | null;
  showWebsite: boolean | null;
  showCompanyInfos: boolean | null;
  showQRcode: boolean | null;
  phone: string | null;
  mail: string | null;
  facebook: string | null;
  twitter: string | null;
  linkedin: string | null;
  instagram: string | null;
  website: string | null;
  companyInfos: string | null;
  isContactable: boolean | null;
  isActive: boolean | null;
}
export interface PublicCategory {
  id: number;
  name: string;
  description: string;
  selectedProducts: PublicSelectedProduct[];
  media: null | Media;
  position: number;
}

export interface PublicSelectedProduct {
  id: number;
  product: PublicProduct;
  position: number;
}

export interface PublicProduct {
  id: number;
  name: string;
  description: string;
  priceHt?: number;
  priceTtc?: number;
  medias: Media[];
}

export interface Pagination {
  last: number;
  current: number;
  numItemsPerPage: number;
  first: number;
  pageCount: number;
  totalCount: number;
  pageRange: number;
  startPage: number;
  endPage: number;
  pagesInRange: number[];
  firstPageInRange: number;
  lastPageInRange: number;
  currentItemCount: number;
  firstItemNumber: number;
  lastItemNumber: number;
}

export interface PageItem<T> {
  pagination: Pagination;
  items: T[];
}

export interface Capture {
  name: string;
  uri: string;
  uri_thumbnail: string;
  type: string;
  file: string;
  creationDate: string;
}

export enum FilterType {
  DATE_RECENT = "DATE_RECENT",
  DATE_OLD = "DATE_OLD",
}
