import style from "./PopOver.module.css";
import classNames from "classnames";
import { useEffect, useRef } from "react";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  clickOutside: () => any;
}

export default function PopOver({ clickOutside, ...other }: Props) {
  const menu = useRef<any>(null);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, false);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e: any) => {
    if (menu.current && !menu.current.contains(e.target)) {
      clickOutside();
    }
  };

  return (
    <div ref={menu} {...other} className={classNames(style.container, other.className)}>
      {other.children}
    </div>
  );
}
